import React, {useState, useRef} from 'react'
import {useSelector} from 'react-redux'
import Utils from '../../utils/utils'
import SearchTerms from './search-terms'

import TooltipApi from '../../redux/api/tooltip-api'

const Search = () => {
	let term = useRef(null)
	let [listTerms, setListTerms] = useState(Utils.getLocalStorage('listTerms') || [])
	let [inResults, setInResults] = useState(null)
	let openSearch = useSelector(state => state.tooltipState.search)

	const getTerm = () => term.current.value;
	const setTerm = (value) => term.current.value = value;

	// Events Inputs Change
	const handleKeyPress = (event) => {
		if (event.key === 'Enter' && getTerm().length > 1)
			searchTerm()
	}

	const keydown = (event) => {
		if (event.key === "Backspace" && !getTerm() && !!inResults)
			setInResults(null)
	}

	const BackView = () => {
		setTerm(null)
		if (!inResults){
			TooltipApi.Search(!openSearch)
		} else {
			setInResults(null)
		}
		Utils.gaSend('', Utils.gaEvents().Action.Search, Utils.gaEvents().Label.SetaVoltar)
	}

	const searchTerm = (item = null) => {
		if (item) {
			setTerm(item)
		}	else {
			item = getTerm()
		}

		if (!item) return

		let currentList = new Set(listTerms)
		currentList.add(item);
		let newList = Array.from(currentList)
		setListTerms(newList)
		Utils.setLocalStorage('listTerms', newList)
		setInResults(item)
	}

	const removeTerm = (item) => {
		let currentList = new Set(listTerms)
		currentList.delete(item);
		let newList = Array.from(currentList)
		setListTerms(newList)
		Utils.setLocalStorage('listTerms', newList)
	}

	const closeModal = () =>{
		TooltipApi.Search(!openSearch)
		Utils.gaSend('', Utils.gaEvents().Action.Search, Utils.gaEvents().Label.ClicarForaDaModal)
	}

	return (
		<div className={"search " + (openSearch ? "fadeIn" : "fadeOut")}>

			<div className="modal-close" onClick={() => {closeModal()}}/>

			<div className="container">
				<div className="content">
					<header>
						<div className='button type-link' onClick={BackView}>
							<div className="icon icon-back"/>
						</div>
						<input type="text" ref={term} name="Terms" placeholder={'Buscar...'} onKeyPress={handleKeyPress}
						       onKeyDown={keydown}/>
						{term &&
						<div className='button type-link clean' onClick={BackView}>
							<span className={"icon icon-clean"}/>
						</div>
						}
						<div className='button type-link' onClick={()=>searchTerm()}>
							<span className={"icon icon-search"}/>
						</div>
					</header>

					{!inResults ? (
						<>
							{listTerms && listTerms.map((item, index) =>
								<div className='item history' key={index}>
									<div className="container">
									<span onClick={() => searchTerm(item)}>
										{item}
									</span>
										<div className='button type-link' onClick={() => removeTerm(item)}>
											<div className="icon icon-clean"/>
										</div>
										<div className="pipe"/>
									</div>
								</div>
							)}
						</>
					) : (
						<SearchTerms Terms={inResults}/>
					)}
				</div>
			</div>
		</div>
	);
}

export default Search
