import React, { useState } from 'react';
import dbModel from '../../models/DB.models';
import appConfig from '../../application/config';

import TonesList from './tones-list';
import Collapse from './collapse';
import Utils from '../../utils/utils';

const ArtistList = (props) => {
	let [loading, setLoading] = useState(false);
	let [ArtistList, setArtistList] = useState(props.List);

	const Search = (_name, _index) => {
		setLoading(true);
		const getSearch = new dbModel(false, true);
		return getSearch({
			method: 'post',
			url: appConfig.apiConnection.paths.Tone.Search,
			data: {
				'endRecordNum': 50,
				'startRecordNum': 0,
				'tone': {
					'singer': {
						'name': _name
					}
				}
			}
		}).then(res => {
			let newArtistList = JSON.parse(JSON.stringify(ArtistList)); // Clone Json Artist
			newArtistList[_index].tones = res.data.tones;
			setArtistList(newArtistList);
		}).catch((error) => {
			console.error(error);
		}).finally(() => setLoading(false));
	};

	const HandleSelect = (_Target, _name, _index) => {
		if (loading || ArtistList[_index].tones) return Promise.resolve();
		if (!ArtistList[_index].tones)
			return Search(_name, _index);
	};

	return (
		<div className="tonos-list">
			{ArtistList && ArtistList.map((item, index) =>
				<Collapse key={index} caption={item.name} handleClick={(e) => HandleSelect(e, item.name, index)}>
					{(item.tones && !item.tones.length) ?
						<p className="no-items">Sin tonos</p>
						:
						<TonesList List={item.tones} AreaName={Utils.gaEvents().Label.Search} TabName={Utils.gaEvents().Label.Artistas} />
					}
				</Collapse>
			)}
			{(ArtistList && !ArtistList.length) && <p className="no-items">Sin contactos</p>}
		</div>
	);
};

export default ArtistList;
