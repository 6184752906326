import React, {useState, useEffect} from 'react';

export default function FigureTone(props) {
  let [styleFigure, setStyleFigure] = useState({})

  useEffect(() => {
    if(!props.Tone) return
    getImage(props.Tone)
  }, [])

  const preLoadImage = (src) => {
    let img = new Image()
    img.src = src
    img.onload = () => {
      setStyleFigure({backgroundImage: 'url(' + src + ')'})
    }
  }
  
	const getImage = (item)=>{
		if (item.albumImageUrl){
      preLoadImage(item.albumImageUrl)
    }else if(item.albumImages.HD && !item.albumImages.HD.includes('_SEM FOTO')){
      preLoadImage(item.albumImages.HD)
    }
  }

  return (
    <figure style={styleFigure}>
      {props.children}
    </figure>
  );
}
