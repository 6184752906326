import Modal from './modal'
import React, {useEffect} from 'react'

import appConfig from '../../application/config'
import Router from "../../utils/router";
import UserApi from '../../redux/api/user-api'

const PerfilDowngrade = () => {
	const webRoutes = appConfig.web.routes
	useEffect(() => {
		setTimeout(()=>{
			UserApi.info();
			Router.goTo({pathname: webRoutes.perfil});
		}, 3000)
	},[])
	return (
		<Modal
			bg={''}
			type={'3'}
			hideClose={true}
			content={
				<>
					<p>Los tonos para tu celular han sido cancelados</p>
				</>
			}
		/>
	)
}

export default PerfilDowngrade


