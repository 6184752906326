import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import Modal from './modal'
import User from "../../utils/user";
import appConfig from "../../application/config";

import ErrorMessage from '../shared/errorMessage'
import ButtonGooglePlay from '../buttons/button-googleplay'

const ContestoneMasOk = () => {
	/* const location = useLocation()
	let [errorType, setErrorType] = useState(false)

	const msisdn = location.state.msisdn
	const pincode = location.state.pincode

	const subscribe = () => {
		User.subscribe(appConfig.planTypes.FREEMIUM, msisdn, pincode).then(() =>{
			User.redirect()
		}).catch((err)=>{
			setErrorType(err)
		})
	} */

	return (
		<Modal
			bg={'bg-graph-1'}
			type={'6'}
			content={
				<>
					<figure className='logotype'/>
					<p className='font-weight-bold'>¡Bienvenido a<br/>Contestone Más!</p>
					<p>Descarga el app para activar ringtones.</p>
					<ButtonGooglePlay/>
				</>
			}
		/>
	)
}

export default ContestoneMasOk


