import store from '../store'
import * as types from '../actions';

class LoadingApi {

  static startLoading(state){
    store.dispatch({
      type: types.START_LOADING
    })
  }

  static stopLoading(state){
    store.dispatch({
      type: types.STOP_LOADING
    })
  }
}

export default LoadingApi