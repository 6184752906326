import {LANDING_USER_PLAN, LANDING_DATA, LANDING_MSISDN, LANDING_SCREEN, LANDING_ERROR} from '../actions'

const initialState = {
	partnerId: null,
	userPlan: null,
	toneId: null,
	screen: null,
	msisdn: null,
	error: null,
	type: null
};

export const landingReducer = (state = initialState, action) => {
	switch (action.type) {

		case LANDING_ERROR:
			return {
				...state,
				error: action.error,
			};

		case LANDING_SCREEN:
			return {
				...state,
				screen: action.screen,
			};

		case LANDING_MSISDN:
			return {
				...state,
				msisdn: action.msisdn,
			};

		case LANDING_DATA:
			return {
				...state,
				typeId: action.typeId,
				toneId: action.toneId,
				partnerId: action.partnerId,
			};

    case LANDING_USER_PLAN:
			return {
        ...state,
				userPlan: action.userPlan
      };

		default:
			return state;
	}
};