import React from 'react'
import Modal from './modal'
import User from "../../utils/user";
import Utils from "../../utils/utils";
import Router from "../../utils/router";
import appConfig from "../../application/config";

const Gratis5Tones = () => {
	const webRoutes = appConfig.web.routes;

	const subscribe = () => {
		Utils.gaSend('', Utils.gaEvents().Action.UpSelling, Utils.gaEvents().Label.Ilimitado5Tonos+' - '+Utils.gaEvents().Label.Assinar)
		User.subscribe(appConfig.planTypes.UNLIMITED).then(() =>
			Router.goTo({pathname: webRoutes.tones, state: {openModal: {type: webRoutes.upsellRbtOk } }})
		)
	}

	return (
		<Modal
			bg={'bg-graph-1'}
			type={'6'}
			AreaName={Utils.gaEvents().Action.UpSelling}
			Label={Utils.gaEvents().Label.Ilimitado5Tonos}
			content={
				<>
					<figure className='logotype'/>
					<p>Haz alcanzado el límite de 5 tonos gratuitos, para activar más tonos para quien te llama suscríbete a Contestone Ilimitado por $4.99 semanal IVA incluido</p>
					<div className="buttons">
						<div className="button type-1">
							<span onClick={subscribe}>
								subscribir
							</span>
						</div>
					</div>
				</>
			}
		/>
	)
}

export default Gratis5Tones


