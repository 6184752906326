/*
 * Store
 * Selects the store based on browser's host
 */

import { Stores } from '../../application/config';

const storesConfig = {
	mexico: require('./mexico'),
	brasil: require('./brasil')
};

class storeState {
	constructor(location) {
		const self = this;
		this.location = location;
		this.store = storesConfig['mexico'];

		this.ResolveStore = (resolve, reject) => {
			for (let envStore in Stores) {
				let regex = RegExp(Stores[envStore].host[process.env.REACT_APP_ENV]);
				//console.log("REGEX -->", regex.toString());
				if (regex.test(self.location)) {
					self.store = storesConfig[envStore];
					//console.log("STORE -->", self.store);
					resolve(self.store);
				}
			}
		};
		return new Promise(this.ResolveStore);
	}
}

export default storeState;
