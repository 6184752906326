import { combineReducers } from 'redux';

import { userReducer } from './user-reducer';
import { colorReducer } from './color-reducer';
import { storeReducer } from './store-reducer';
import { loadingReducer } from './loading-reducer';
import { tooltipReducer } from './tooltip-reducer';
import { catalogReducer } from './catalog-reducer';
import { previewReducer } from './preview-reducer';
import { landingReducer } from './landing-reducer';
import { redirectReducer } from './redirect-reducer';
import { editorialReducer } from './editorial-reducer';

export const reducers = combineReducers({
  userState: userReducer,
  colorState: colorReducer,
  storeState: storeReducer,
  loadingState: loadingReducer,
  tooltipState: tooltipReducer,
  catalogState: catalogReducer,
  previewState: previewReducer,
  landingState: landingReducer,
  redirectState: redirectReducer,
  editorialState: editorialReducer,
});

export default reducers;