import store from '../store';
import * as types from '../actions';

class ColorApi {

	static setColorTab(state) {
		store.dispatch({
			type: types.COLOR_TAB,
			state
		});
	}

	static setColorStripe(state) {
		store.dispatch({
			type: types.COLOR_STRIPE,
			state
		});
	}

	static setColorStripeCategory(state) {
		store.dispatch({
			type: types.COLOR_STRIPE_CATEGORY,
			state
		});
	}

	static setColorFont(state) {
		store.dispatch({
			type: types.COLOR_FONT,
			state
		});
	}
}

export default ColorApi;



