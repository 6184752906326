import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import EditorialApi from '../../redux/api/editorial-api';
import Modal from './modal'
import Utils from '../../utils/utils'

const Terms = () => {
	let terms = useSelector(store => store.editorialState.terms)
	let [content, setContent] = useState(null);

	useEffect(() => {
		if (!terms)
			EditorialApi.getTerms().catch((err) => {
				setContent(err.error);
			});
		else
			setContent(terms);
	}, [terms]);

	useEffect(() => {
		Utils.gaSend('', Utils.gaEvents().Action.Termos)
	},[])

	return (
		<Modal
			type={'5'}
			title={'Términos y condiciones'}
			content={
				<>
					<section className='terms'>
						<div className='container' dangerouslySetInnerHTML={{__html: content}}/>
					</section>
				</>
			}
		/>
	);
}

export default Terms