import React, {useRef, useEffect} from 'react';
import { useSelector } from 'react-redux'

import PreviewApi from '../../redux/api/preview-api'

const Player = () => {
  const Player = useRef()
  const Preview = useSelector(store => store.previewState)
  let ToneInfo, isPlaying, audioControl = false

  try{
    ToneInfo = Preview.infoPreview
    isPlaying = Preview.isPlaying
    audioControl = Player.current
  }catch(e){}

  useEffect(() => {
    if(!audioControl || !ToneInfo) return
      Play()
  }, [ToneInfo])

  useEffect(() => {
    if(!audioControl || isPlaying) return
      Stop()
  }, [isPlaying])

  const Play = () => {
    audioControl.src = ToneInfo.previewUrl
  }
  const Stop = () => {
    audioControl.pause()
    audioControl.currentTime = 0
    audioControl.src = ''
    PreviewApi.Stop(true)
  }
  const CanPlay = () =>{
    PreviewApi.isPlaying(true)
    audioControl.play()
  }
  const LoadStart = ()=> {
    PreviewApi.Loading(true)
  }
  const Waiting = ()=> {
    PreviewApi.Loading(true)
  }
  const Playing = ()=> {
    PreviewApi.Loading(false)
  }

  return (
    <>
      <audio ref={Player}
        onEnded      =  {Stop}
        onCanPlay    =  {CanPlay}
        onLoadStart  =  {LoadStart}
        onPlaying    =  {Playing}
        onWaiting    =  {Waiting}
      />
    </>
  )
};

export default Player;
