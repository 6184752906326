import React, {useState} from 'react'
import Utils from '../../utils/utils'

const Collapse = (props) => {

	const arrowIcon = "icon-back"
	let [arrow, setArrow] = useState(arrowIcon);
	let [collapsed, setCollapsed] = useState(true);

	const handleClick = () => {
		if (props.handleClick){
			setArrow("icon-loading")
			props.handleClick().finally(() => {
				setArrow(arrowIcon)
				setCollapsed(!collapsed)
			})
		} else if (props.blockClick){
			props.blockClick(props.label).then(() => {
				setArrow(arrowIcon)
				setCollapsed(!collapsed)
			})
		} else {
			setCollapsed(!collapsed)
		}

		if(collapsed && props.AreaName){
			Utils.gaSend('', props.AreaName, Utils.gaEvents().Label.CliqueNaPergunta + ' - ' + props.item)
		}
	};

	return (
		<div className={"item collapse" + (collapsed ? "" : " on")}>
			<div className="container" onClick={handleClick}>

				{!!props.icon &&
				<div className="button type-link">
					<div className={"icon " + props.icon}/>
				</div>
				}

				<span>{props.caption}</span>

				<div className='button type-link'>
					<div className={"icon " + arrow}/>
				</div>
			</div>
			<section className={"list"}>
				{props.children}
			</section>
		</div>
	)
}

export default Collapse
