// REACT
import React, {useEffect} from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import {useHistory, useLocation} from 'react-router-dom';

// COMPONENTS
import AppRouter from '../../router';
import Header from './header';
import Footer from './footer';
import Loading from '../shared/loading';
import ModalRouter from '../../modal-router';

//Utils
import User from '../../utils/user';
import Utils from '../../utils/utils';
import Router from '../../utils/router';
import appConfig from '../../application/config';
import Search from '../search/search';

// REDUX API
import UserApi from '../../redux/api/user-api';
import Player from '../machine/player';
import TooltipTone from '../shared/tooltip-tone';

const Main = () => {
	Router.setHistory(useHistory());
	const webRoutes = appConfig.web.routes;

	const location = useLocation();
	const pageURL = location.pathname.split('/')[1];
	const noHeaderFooter = ['landing'];
	let hiddenHEaderFooter = noHeaderFooter.includes(pageURL);

	const store = useSelector(state => state.storeState);
	const user = useSelector(state => state.userState);
	const tabState = useSelector(state => state.colorState.tab);
	const pageTitle = store.config.page.title;
	let colorTab = tabState ? tabState : '#ef423e';

	useEffect(()=>{
		if(Utils.getLocalStorage('userToken')){
			UserApi.info();
		} else {
			loginHe();
		}
		// Get Plan List
		if(!user.planList.length)
			UserApi.getPlanList();
	},[user.planList.length]);

	const loginHe = () => {
		return UserApi.he().then(data => {
			if (data.msisdn)
				UserApi.login(data.msisdn, data.validateCode)
					.then(Router.goToRoot);
		});
	};

	return (
		<section className={'store-' + store.config.ct}>
			<Helmet title={pageTitle} />

			{hiddenHEaderFooter ? '' : <Header />}
			<Route component={AppRouter} />
			{hiddenHEaderFooter ? '' : <Footer />}
			
			<ModalRouter />
			
			<Search />

			{/* <Support /> */}
			<Loading />
			<Player />
			<TooltipTone/>
		</section>
	);
};

export default Main;
