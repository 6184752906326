// POLYFILLS
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';

// REACT
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';

import IntlSwapProvider from './components/providers/intlSwapProvider';
import ProviderStore from './components/providers/ProviderStore';

// PROVIDERS
import Store from './redux/store';

// COMPONENTS
import Main from './components/core/main';

// ASSETS
// import './assets/css/main.min.css';
import './assets/css/main.css';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(
	<Provider store={Store}>
		<BrowserRouter>
			<ProviderStore>
				<IntlSwapProvider>
					<Route component={Main} />
				</IntlSwapProvider>
			</ProviderStore>
		</BrowserRouter>
	</Provider>
	, document.getElementById('main'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


