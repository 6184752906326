import React, { memo } from 'react';
import appConfig from '../../../application/config';

const PromoHighlight = memo(({ item, index }) => {
	return <React.Fragment>
		<div className="card-promo">
			<div className="container">
				<div className="content">
					<figure style={{ backgroundImage: 'url(' + appConfig.apiConnection.imagesAssetsAPI[process.env.REACT_APP_ENV] + item.url + ')' }}></figure>
				</div>
			</div>
		</div>
	</React.Fragment>;
});

export default PromoHighlight;
