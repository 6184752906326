import React from 'react'

import appConfig from '../../application/config';

import logotype from "../../assets/svg/logotype-contestone.svg";
import {useSelector} from "react-redux";
import UserUtils from "../../utils/user";
import LandingApi from "../../redux/api/landing-api";

const LandingError = () => {
	const {msisdn, error} = useSelector(state => state.landingState);

	const backButtonClick = () => {
		LandingApi.beginFlux(msisdn);
	}

	return (
		<section className="error">
			<figure className="logotype">
				<img src={logotype} alt=""/>
			</figure>
			<p className="message">{error}</p>
			<div className="button type-4" onClick={backButtonClick}>
				<span>volver</span>
			</div>
			<p className="condition">Caso persista el error, póngase en<br />contacto con su operadora</p>
		</section>
	)
}

export default LandingError;


