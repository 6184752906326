import store from "../../store";
import Router from "../../../utils/router";
import appConfig from "../../../application/config";
import Utils from "../../../utils/utils";
import UserUtils from "../../../utils/user";
import LandingApi from "../landing-api";

class Type2 {
	static firstStep() {
		const landingState = store.getState().landingState;

		let landingScreen = (UserUtils.isHe()) ? "he" : "pincode";

		// Contestone mas user
		if (!!landingState.userPlan && LandingApi.isContestoneMas()) {
			return Router.goTo({pathname: appConfig.web.routes.landing.redirect});

			// Unlimited user
		} else if (!!landingState.userPlan  && LandingApi.isUnlimited()) {
			if (Utils.isAndroid()) {
				LandingApi.setScreen("mas");
			} else {
				return Router.goTo({pathname: appConfig.web.routes.landing.redirect});
			}

		} else {
			// Freemium user or not user
			LandingApi.setScreen("unlimited");
		}
		return Router.goTo({pathname: appConfig.web.routes.landing[landingScreen]});
	}
}

export default Type2;