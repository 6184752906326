import React from 'react'
import appConfig from '../../application/config'
import {useSelector} from 'react-redux'
import Utils from '../../utils/utils'

const ButtonGooglePlay = () => {
	const webRoutes = appConfig.web.routes
	const store = useSelector(state => state.storeState)

	return (
		<a href={store.config.googlePlay} onClick={()=> Utils.gaSend('', Utils.gaEvents().Action.GooglePlay, Utils.gaEvents().Label.AbrirGooglePlayStore) } target={'_blank'}>
			<div className='button type-googleplay'/>
		</a>
	)
}

export default ButtonGooglePlay


