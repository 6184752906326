import React, {useEffect} from 'react'
import { useLocation } from 'react-router-dom'
import Modal from './modal'

import User from "../../utils/user"
import Utils from '../../utils/utils'
import Router from "../../utils/router"

const PerfilExit = () => {
	const location = useLocation()

	useEffect(() => {
		if(location.state && location.state.label){
			Utils.gaSend('', Utils.gaEvents().Action.Perfil, location.state.label)
		}
	}, []);

	return (
		<Modal
			bg={''}
			type={'3'}
			content={
				<>
					<p>¿Seguro que deseas cerrar la sesión?</p>
				</>
			}
			footer={
				<>
					<div className="button type-1">
						<span onClick={Router.goToRootReplace}>no</span>
					</div>
					<div className="button type-1">
						<span onClick={User.logout}>cerrar</span>
					</div>
				</>
			}
		/>
	)
}

export default PerfilExit


