import React from 'react';
import { useSelector } from 'react-redux';

import PreviewApi from '../../redux/api/preview-api';
import Utils from '../../utils/utils';

const Play = (props) => {
	const Preview = useSelector(store => store.previewState);

	let isPlaying, ToneInfo, CurrentTone, isCurrentTone, Loading, TonePlaying = false;
	try {
		isPlaying = Preview.isPlaying;
		ToneInfo = Preview.infoPreview;
		CurrentTone = props.Tone;
		isCurrentTone = (JSON.stringify(props.Tone) === JSON.stringify(ToneInfo));
		TonePlaying = isPlaying && isCurrentTone;
		Loading = Preview.loading && isCurrentTone;
	} catch (e) { }

	const handlePlayer = () => {
		if (TonePlaying) {
			PreviewApi.Stop();
		} else {
			PreviewApi.Play(CurrentTone);
			Utils.gaSend('', Utils.gaEvents().Action.PlayPreview, props.Label);
		}
	};

	let Label = 'play';
	if (Loading) {
		Label = 'loading-white';
	} else if (TonePlaying) {
		Label = 'pause';
	}
	return (
		<>
			<a className="button type-3 play" onClick={handlePlayer}>
				<div className={'icon icon-' + Label} />
			</a>
		</>
	);
};

export default Play;
