import React from 'react';
import Router from '../../utils/router';

import Modal from './modal';
import appConfig from '../../application/config';
import UserApi from '../../redux/api/user-api';


const DeleteDataConfirmationSuccess = (props) => {
	const webRoutes = appConfig.web.routes;

	return (
		<Modal
			bg={''}
			type={'3'}
			content={
                <>
					<figure className='logotype'/>
					<p className='font-weight-bold'>Se está procesando tu petición</p>
                    <p>Ya hemos recibido tu solicitud de eliminación de datos. Al finalizar recibirás una confirmación vía SMS.</p>
                    <div className="button-container">
                    	<div className="button type-2" onClick={() => Router.goTo({pathname: webRoutes.home})}>
                    	    <span>concluir</span>
                    	</div>
                    </div>
				</>
			}
		/>
	);
};

export default DeleteDataConfirmationSuccess;


