import store from '../store';
import * as types from '../actions';

import dbModel from '../../models/DB.models'
import appConfig from '../../application/config'

import Utils from '../../utils/utils'
import User from '../../utils/user'

class UserApi {

	static getPlanList() {
		const DB = new dbModel(false, false);
		DB({
			method: 'get',
			url: appConfig.apiConnection.paths.User.PlanList
		}).then(res => {
			const planList = res.data.plans
			store.dispatch({
				type: types.USER_PLAN_LIST,
				planList
			});
		}).catch((error) => {
			console.error(error);
		})
	}

	static he() {
		const DB = new dbModel(false);
		return DB({
			method: 'get',
			url: appConfig.apiConnection.paths.User.Msisdn
		}).then(res => {
			let msisdn = res.data.msisdn
			let validateCode = res.data.validateCode
			store.dispatch({
				type: types.USER_HE,
				he: true
			});
			return { msisdn, validateCode }
		}).catch((error) => {
			console.error(error)
			throw error
		})
	}

	static edit(msisdn, name) {
		const UserToken = Utils.getLocalStorage('userToken')
		const DB = new dbModel()
		return DB({
			method: 'put',
			url: appConfig.apiConnection.paths.User.Edit,
			headers: !!UserToken ? { UserToken } : {},
			data: { msisdn, name }
		}).then(res => {
			let user = res.data
			store.dispatch({
				type: types.USER_NAME,
				user
			});
		}).catch((error) => {
			Utils.clearLocalStorage('userToken')
			console.error(error);
		})
	}

	static info(withoutLoading) {
		const DB = new dbModel(false, withoutLoading)
		const UserToken = Utils.getLocalStorage('userToken')
		return DB({
			method: 'get',
			url: appConfig.apiConnection.paths.User.Info,
			headers: !!UserToken ? { UserToken } : {}
		}).then(res => {
			let user = res.data
			store.dispatch({
				type: types.USER_DATA,
				user
			});
		}).catch((error) => {
			Utils.clearLocalStorage('userToken')
			console.error(error);
		})
	}

	static subscriptionInfo(msisdn) {
		const DB = new dbModel()
		return DB({
			method: 'post',
			url: appConfig.apiConnection.paths.User.SubscriptionInfo,
			data: { msisdn: msisdn || User.msisdn() }
		}).then(res => {
			return res.data.status
		}).catch((error) => {
			throw this.getMessageError('subscriptionInfo', error)
		})
	}

	static sendSmsCode(msisdn) {
		const sendSmsCode = new dbModel(false, true);
		return sendSmsCode({
			method: 'post',
			url: appConfig.apiConnection.paths.User.Validate,
			data: { msisdn }
		}).then(res => {
			return true
		}).catch((error) => {
			throw this.getMessageError('sendSmsCode', error)
		})
	}

	static login(msisdn, validateCode) {
		const DB = new dbModel(false);
		return DB({
			method: 'post',
			url: appConfig.apiConnection.paths.User.Login,
			data: { msisdn, validateCode }
		}).then(res => {
			let user = res.data.user
			Utils.setLocalStorage('userToken', res.data.userToken)
			store.dispatch({
				type: types.USER_DATA,
				user
			});
		}).catch((error) => {
			if (error.response && error.response.data.error === "SUBSCRIPTION_NOT_ACTIVE") {
				store.dispatch({
					type: types.USER_DATA,
					user: { msisdn }
				});
			}
			throw this.getMessageError('login', error)
		})
	}

	static prepareSubscribe(brand, msisdn, pincode) {
		if (msisdn && pincode) {
			return this.subscribe(brand, msisdn, pincode)

		} else {
			return this.he().then(({ msisdn, validateCode }) => {
				return this.subscribe(brand, msisdn, validateCode)
			})
		}
	}

	static subscribe(brand, _msisdn, _validateCode) {
		const DB = new dbModel(false);
		return DB({
			method: 'post',
			url: appConfig.apiConnection.paths.User.Subscribe,
			data: {
				msisdn: _msisdn,
				validateCode: _validateCode,
				plan: { brand, isAvailable: false }
			}
		}).then(res => {
			let user = res.data.user
			Utils.setLocalStorage('userToken', res.data.userToken)
			store.dispatch({
				type: types.USER_DATA,
				user
			});
			return res.data
		}).catch((error) => {
			throw this.getMessageError('subscribe', error)
		})
	}


	static checkSubscriptionCreated() {
		const self = this
		let checkCondition = (resolve, reject, _turn) => {
			let turn = _turn ? _turn + 1 : 1

			self.subscriptionInfo().then((res) => {
				if (res === 'CREATED') {
					resolve(res)
				} else if (turn >= 5) {
					reject('ERROR')
				} else {
					setTimeout(() => { checkCondition(resolve, reject, turn) }, 5000)
				}
			})
		}

		return new Promise(checkCondition)
	}

	static upgrade(brand) {
		const UserToken = Utils.getLocalStorage('userToken')
		const DB = new dbModel(false);
		return DB({
			method: 'post',
			url: appConfig.apiConnection.paths.User.Upgrade,
			headers: !!UserToken ? { UserToken } : {},
			data: {
				msisdn: User.msisdn(),
				plan: { brand, isAvailable: false }
			}
		}).then(res => {
			let user = res.data
			store.dispatch({
				type: types.USER_DATA,
				user
			});
			return user
		}).catch((error) => {
			throw this.getMessageError('upgrade', error)
		})
	}

	static unsubscribe() {
		const UserToken = Utils.getLocalStorage('userToken')
		const msisdn = User.msisdn()
		const DB = new dbModel(false);
		return DB({
			method: 'post',
			url: appConfig.apiConnection.paths.User.Unsubscribe,
			headers: !!UserToken ? { UserToken } : {},
			data: { msisdn }
		}).then(res => {
			User.logout();
			store.dispatch({
				type: types.USER_DATA,
				user: { msisdn }
			});
		}).catch((error) => {
			throw this.getMessageError('unsubscribe', error)
		})
	}


	static userAddTone(tone, userMsisdn) {
		const msisdn = userMsisdn || User.msisdn();
		const DB = new dbModel(false, true)
		const UserToken = Utils.getLocalStorage('userToken')

		return DB({
			method: 'post',
			url: appConfig.apiConnection.paths.Tone.Add,
			headers: { UserToken },
			data: {
				msisdn: msisdn,
				toneBoxType: appConfig.toneBoxTypes.DEFAULT,
				tones: [tone]
			}
		}).then(res => {
			store.dispatch({
				type: types.USER_ADD_TONE,
				toneBox: { ...res.data, tones: [tone] }
			});
		}).catch((error) => {
			throw this.getMessageError('userAddTone', error)
		})
	}

	static userRemoveTone(data) {
		const DB = new dbModel(false, true)
		const UserToken = Utils.getLocalStorage('userToken')
		return DB({
			method: 'post',
			url: appConfig.apiConnection.paths.Tone.Delete,
			headers: { UserToken },
			data
		}).then(res => {
			store.dispatch({
				type: types.USER_REMOVE_TONE,
				toneBox: res.data
			});
		}).catch((error) => {
			throw this.getMessageError('userRemoveTone', error)
		})
	}

	static getMessageError(label, err) {
		let errorType = 'ERROR'
		let Label = label ? label + ' - ' : ''
		try {
			if (err.response.data.providerErrorCode) {
				errorType = errorType + '_' + err.response.data.providerErrorCode
			} else if (err.response.data.error) {
				errorType = errorType + '_' + err.response.data.error
			}
		} catch (e) { }

		Utils.gaSend('', Utils.gaEvents().Action.Error, Label + errorType)
		return errorType
	}

	static deletePersonalData() {
		const DB = new dbModel(false, false);
		const userToken = Utils.getLocalStorage('userToken');
		const msisdn = User.msisdn();
		return DB({
			method: 'delete',
			url: appConfig.apiConnection.paths.User.DeletePersonalData,
			headers: {
				AppVersion: 1000149,
				Channel: 'WEB',
				DeviceInfo: 'zczxzxzxcsc',
				OsVersion: 213,
				Platform: 'adadaasas',
				ct: 'mx',
				userToken,
			},
		}).then(res => {
			User.logout();
		}).catch((error) => {
			throw this.getMessageError('deletePersonalDataApi', error);
		});
	}
}

export default UserApi;



