import React from 'react';
import Router from '../../utils/router';
import Utils from '../../utils/utils';

const Modal = (props) => {

	const closeClick = (_type) => {
		if (props.hideClose) return;
		if(props.saveHistory){
			Router.goToRoot({state: {doScrollTop: true}});
		} else {
			Router.goToRootReplace({state: {doScrollTop: true}});
		}

		if(props.AreaName){
			let Label = (props.Label ? props.Label +' - ' +_type : _type);
			Utils.gaSend('', props.AreaName, Label);
		}
	};

	return (
		<>
			<div className="modal-close" onClick={(e)=>{closeClick(Utils.gaEvents().Label.ClicarForaDaModal);}}/>
			<div className={'container ' + (props.bg && props.bg) + ' ' + (props.type ? 'type-' + props.type : 'type-1')}>
				<header>
					<h1>{props.title}</h1>
					{!props.hideClose &&
					<div className="button type-link" onClick={(e)=>{closeClick(Utils.gaEvents().Label.FecharModal);}}>
						<div className="icon icon-cancel"/>
					</div>
					}
				</header>
				<div className="content">
					{props.content}
				</div>
				{props.footer && <footer>{props.footer}</footer>}
			</div>
		</>
	);
};

export default Modal;


