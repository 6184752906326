import store from '../../store';
import Router from '../../../utils/router';
import appConfig from '../../../application/config';
import UserUtils from '../../../utils/user';
import LandingApi from '../landing-api';

class Type5 {
	static firstStep(){
		const landingState = store.getState().landingState;
		let landingScreen = (UserUtils.isHe()) ? 'he' : 'pincode';

		if (!!landingState.userPlan && LandingApi.isUnlimited() || LandingApi.isContestoneMas()) {
			return Router.goTo({pathname: appConfig.web.routes.landing.redirect});
		} else {
			LandingApi.setScreen('unlimited');
		}
		return Router.goTo({pathname: appConfig.web.routes.landing[landingScreen]});
	}
}

export default Type5;
