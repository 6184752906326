import {USER_RESET, USER_REMOVE_TONE, USER_ADD_TONE, USER_DATA, USER_PLAN_LIST, USER_HE, USER_NAME} from '../actions'

const initialState = {
	info: {
    he: false,
    msisdn: null,
		subscription: {
			plan: {}
		},
		toneBoxes: []
	},
	planList: {}
}

export const userReducer = (state = initialState, action) => {
	switch (action.type) {

		case USER_RESET:
			return initialState;

		case USER_ADD_TONE: {
      let toneBoxes = state.info.toneBoxes;
      if (!toneBoxes.length) {
        toneBoxes.push(action.toneBox)
      } else {
        let toneBox = toneBoxes.find(tb => tb.id === action.toneBox.id)
        if (toneBox) {
          toneBox.tones.push(action.toneBox.tones[0])
        } else {
          toneBoxes.push(action.toneBox)
        }
      }
      return {
        ...state,
        info: {
          ...state.info,
          toneBoxes
        }
      }
    }

		case USER_REMOVE_TONE: {
      let toneBoxes = state.info.toneBoxes;
      if (toneBoxes.length) {
        let tbIndex = toneBoxes.findIndex(tb => tb.id === action.toneBox.id)
        if (tbIndex > -1) {
          let tIndex = toneBoxes[tbIndex].tones.findIndex(t => t.id === action.toneBox.tones[0].id)
          if (tIndex > -1)
          	if (toneBoxes[tbIndex].tones.length > 1)
              toneBoxes[tbIndex].tones.splice(tIndex, 1);
          	else
		          toneBoxes.splice(tbIndex, 1)
        }
      }
      return {
        ...state,
        info: {
          ...state.info,
          toneBoxes
        }
      }
    }

		case USER_DATA:
			return {
				...state,
				info: {
					...state.info,
					...action.user
				}
      };
      
    case USER_NAME:
      return {
        ...state,
        info: {
          ...state.info,
          name: action.user.name
        }
      };

		case USER_PLAN_LIST:
			return {
				...state,
				planList: action.planList
      };
      
    case USER_HE:
			return {
        ...state,
				info: {
          ...state.info,
          he: action.he
        }
      }

		default:
			return state;
	}
};