import React, {useEffect} from 'react'
import Modal from './modal'

import User from "../../utils/user";
import Router from '../../utils/router'

const ContestoneMasAgain = () => {
	useEffect(() => {
		setTimeout(Router.goToRoot, 3000)
	},[])
	return (
		<Modal
			bg={''}
			type={'3'}
			hideClose={true}
			content={
				<>
					<p>¡Bienvenido de vuelta!</p>
				</>
			}
		/>
	)
}

export default ContestoneMasAgain


