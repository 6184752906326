import React, {useState} from "react";
import {useSelector} from "react-redux";
import {NavLink, Link} from 'react-router-dom'
import appConfig from "../../application/config";
import { FormattedHTMLMessage } from 'react-intl'
import Utils from "../../utils/utils";

const PinCode = (props) => {
	const webRoutes = appConfig.web.routes
	const Label = Utils.gaEvents().Label.Confirmar + ' - ' + Utils.gaEvents().Label.IdentificacaoSMS

	let [pincode, setPincode] = useState('')

	const store = useSelector(state => state.storeState.config);
	const pincodeLength = store.validateCode.size;

	const handleChange = (e) => {
		let value = e.target.value.replace(/[^\d]+/, "")
		setPincode(value);
		e.target.value = value;
	}

	const handleKeyPress = (event) => {
		if (event.key === 'Enter'){
			props.validate(pincode, Label)
		}
	}

	return (
		<>
			<p className="font-weight-bold"><FormattedHTMLMessage id={'PINCODE_TEXT_1'} /></p>

			<div className={"form-box " + (props.message.type)}>
				<div className="description"><FormattedHTMLMessage id={'PINCODE_TEXT_2'} /></div>
				<div className="content">
					<input className='text-align-center' type="tel" name={'pincode'} onChange={handleChange} onKeyPress={handleKeyPress} maxLength={pincodeLength}/>
				</div>
				{props.message.type && <div className={"feedback " + (props.message.type)}>{<FormattedHTMLMessage id={props.message.text} />}</div>}
				
				{!(props.message.type === "success") && 
					<div className="link">
						<a href="" onClick={(e)=>{e.preventDefault(); props.sendSms(props.msisdn, true, Utils.gaEvents().Label.ReenviarCodigo)}}><FormattedHTMLMessage id={'PINCODE_RESEND_SMS'} /></a>
					</div>
				}
			</div>

			<div className="form-box">
				<div className="buttons">
					<div className="content">
						<div className={"button type-2" + (!pincode || pincode.length < pincodeLength ? " disabled" : "")} onClick={() => props.validate(pincode, Label)}>
							<FormattedHTMLMessage id={'BTN_CONFIRM'} />
						</div>
					</div>
				</div>
			</div>

			<div className="link">
				<FormattedHTMLMessage id={'TERMS_TEXT_1'} /> <NavLink to={webRoutes.terms}><FormattedHTMLMessage id={'TERMS_TITLE'} /></NavLink>
			</div>
		</>
	)
}

export default PinCode