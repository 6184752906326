import React, {useEffect} from 'react';
import {Route, Switch, useParams} from 'react-router-dom';

import appConfig from '../../application/config';

import LandingApi from '../../redux/api/landing-api';
import LandingHe from '../landing/he';
import landingError from '../landing/error';
import LandingMsisdn from '../landing/msisdn';
import LandingPincode from '../landing/pincode';
import LandingRedirect from '../landing/redirect';
import UserUtils from '../../utils/user';
import Router from '../../utils/router';
import UserApi from '../../redux/api/user-api';

const Landing = () => {
	const webRoutes = appConfig.web.routes;
	const params = useParams();

	useEffect(() => {
		LandingApi.setParamsData({
			partnerId: params.partnerId,
			typeId: params.type,
			toneId: params.toneId
		});
		UserApi.he().then(data => {
			if (UserUtils.isHe()) {
				LandingApi.setMsisdn(data.msisdn);
				LandingApi.beginFlux(data.msisdn);
			} else {
				Router.goTo({pathname: webRoutes.landing.msisdn});
			}
		}).catch(() => {
			Router.goTo({pathname: webRoutes.landing.msisdn});
		});
	}, []);

	return (
		<section className="landing">
			<div className="container">
				<div className="content">
					<Switch>
						<Route path={webRoutes.landing.he} component={LandingHe}/>
						<Route path={webRoutes.landing.error} component={landingError}/>
						<Route path={webRoutes.landing.msisdn} component={LandingMsisdn}/>
						<Route path={webRoutes.landing.pincode} component={LandingPincode}/>
						<Route path={webRoutes.landing.redirect} component={LandingRedirect}/>
					</Switch>
				</div>
				<footer></footer>
			</div>
		</section>
	);
};

export default Landing;


