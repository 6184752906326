import { SET_CATALOG, SET_CATEGORY_LIST } from '../actions'

const initialState = {
  catalog: [],
  categoryList: []
}

export const catalogReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATALOG:
      return {
        ...state,
        catalog: action.catalog
      };
    case SET_CATEGORY_LIST:
        return {
          ...state,
          categoryList: action.categoryList
        };
    default:
      return state;
  }
};