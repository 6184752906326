import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import Modal from './modal'
import Utils from '../../utils/utils'

const Rules = () => {
	let rules = useSelector(store => store.editorialState.rules)
	let [content, setContent] = useState(rules)

	useEffect(() => {
		setContent(rules.content);
	}, [rules]);

	useEffect(() => {
		Utils.gaSend('', Utils.gaEvents().Action.Rules)
	}, [])

	return (
		<Modal
			type={'5'}
			title={'Rules'}
			content={
				<>
					<section className='terms'>
						<div className='container' dangerouslySetInnerHTML={{ __html: content }} />
					</section>
				</>
			}
		/>
	);
}

export default Rules