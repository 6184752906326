import Utils from '../utils/utils';
import appConfig from '../application/config';

const webRoutes = appConfig.web.routes;

class Router {

	static getHistory() {
		return Router.history;
	}

	static setHistory(history) {
		Router.history = history;
	}

	static goToModal(location){
		Router.getHistory().replace({pathname: location.pathname, state: location.state});
	}

	static goTo(location) {
		Router.getHistory().push({pathname: location.pathname, state: location.state});
	}

	static goToReplace(location) {
		Router.getHistory().replace({pathname: location.pathname, state: location.state});
	}

	static goToRoot(location) {
		let pathname = Utils.getLocalStorage('linkRoot');
		if (!pathname) pathname = webRoutes.home;
		Router.getHistory().push({pathname, state: (location) ? location.state : null});
	}

	static goToRootReplace(location) {
		let pathname = Utils.getLocalStorage('linkRoot');
		if (!pathname) pathname = webRoutes.home;
		Router.getHistory().replace({pathname, state: (location) ? location.state : null});
	}

	static goBack() {
		Router.getHistory().goBack();
	}
}

export default Router;
