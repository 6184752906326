import { SET_STORE } from '../actions'

const initialState = {
  config: ''
}

export const storeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STORE:
      return {
        ...state,
        config: action.state
      };
    default:
      return state;
  }
};