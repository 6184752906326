import React, {useState} from "react";
import {useSelector} from "react-redux";
import { FormattedHTMLMessage } from 'react-intl'
import Utils from "../../utils/utils";

const SendSms = (props) => {

	let [msisdn, setMsisdn] = useState(null)

	const store = useSelector(state => state.storeState.config);
	const msisdnLength = store.msisdn.maxLength;

	const handleChange = (e) => {
		let value = e.target.value.replace(/[^\d]+/, "")
		setMsisdn(value);
		e.target.value = value;
	}

	const handleKeyPress = (event) => {
		if (event.key === 'Enter')
			sendSms(msisdn)
	}

	const sendSms = () => {
		if(msisdn && msisdn.length == msisdnLength){
			props.sendSms(msisdn)
			Utils.gaSend('', Utils.gaEvents().Action.Login, Utils.gaEvents().Label.Seguir + ' - ' + Utils.gaEvents().Label.Msisdn)
		}
	}

	return (
		<>
			<figure className='logotype'/>
			<p><FormattedHTMLMessage id={'SEND_SMS_TITLE'} /></p>

			<div className={"form-box " + (props.message.type)}>
				<div className="content">
					<input className='text-align-center' type="tel" name={'msisdn'} onChange={handleChange} onKeyPress={handleKeyPress} maxLength={msisdnLength}/>
				</div>
				
				{props.message.type && <div className={"feedback " + (props.message.type)}>{<FormattedHTMLMessage id={props.message.text} />}</div>}
			</div>

			<div className="buttons" onClick={() => sendSms(msisdn)}>
				<div className={"button type-2" + (!msisdn || msisdn.length < msisdnLength ? " disabled" : "")}>
					<span><FormattedHTMLMessage id={'BTN_NEXT'} /></span>
				</div>
			</div>
		</>
	)
}

export default SendSms