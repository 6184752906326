import Modal from './modal'
import React, {useEffect} from 'react'
import User from '../../utils/user'

const PerfilCancelUnlimited = () => {
	useEffect(() => {
		setTimeout(User.logout, 3000)
	},[])

	return (
		<Modal
			bg={''}
			type={'3'}
			hideClose={true}
			content={
				<>
					<p>Contestone ilimitado ha sido cancelado</p>
				</>
			}
		/>
	)
}

export default PerfilCancelUnlimited


