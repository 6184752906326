import store from '../store'
import * as types from '../actions';

class PreviewApi {
  static isPlaying(state){
    store.dispatch({
      type: types.IS_PLAYING,
      state
    })
  }
  static Play(state){
    store.dispatch({
      type: types.PLAY_PREVIEW,
      state
    })
  }
  static Loading(state){
    store.dispatch({
      type: types.LOADING_PREVIEW,
      state
    })
  }
  static Stop(state){
    store.dispatch({
      type: types.STOP_PREVIEW,
      state
    })
  }
}

export default PreviewApi