import store from '../store';
import * as types from '../actions';

import dbModel from '../../models/DB.models';
import appConfig from '../../application/config';

class CatalogApi {
	/**
	 * Set value that was clicked
	 */

	static getCatalog() {
		const axios = new dbModel(false, true);

		axios({
			method: 'get',
			url: appConfig.apiConnection.paths.Catalog,
		}).then((res) => {

			console.log(res.data);

			store.dispatch({
				type: types.SET_CATALOG,
				catalog: res.data.catalogs,
			});
		}).catch((error) => {
			console.error(error);
		});
	}

	static getCategoryList(_ct) {
		const axios = new dbModel(false, true);

		axios({
			method: 'get',
			url: appConfig.apiConnection.paths.CategoryList,
		}).then((res) => {
			store.dispatch({
				type: types.SET_CATEGORY_LIST,
				categoryList: res.data.catalogs,
			});
		}).catch((error) => {
			console.error(error);
		});
	}
}

export default CatalogApi;
