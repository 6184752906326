import React, {useEffect, useState} from 'react'
import { FormattedHTMLMessage } from 'react-intl'

const ErrorMessage = (props)=>{
  let [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if(props.type){
      dispatchError(props.type)
    }
  }, [props.type])

  const dispatchError = (error) => {
		setErrorMessage(error)
		setTimeout(() => {
      setErrorMessage(null)
      props.resetErrorType('')
		}, 5000)
  }
  
  return (
    <>
      {errorMessage && <p className="color-red-1">{<FormattedHTMLMessage id={errorMessage} />}</p>}
    </>
  )
}

export default ErrorMessage