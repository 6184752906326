import store from '../redux/store';
import appConfig from '../application/config'
import {REDIRECT_RESET, USER_RESET, START_LOADING, STOP_LOADING} from "../redux/actions"

import Utils from "../utils/utils"
import Router from "../utils/router"

import UserApi from "../redux/api/user-api"

const webRoutes = appConfig.web.routes;
const planTypes = appConfig.planTypes;
const planCodes = appConfig.planCodes;
const statusTypes = appConfig.statusTypes;

class User {

	/**
	 * Close user session
	 */
	static logout() {
		const webRoutes = appConfig.web.routes;

		Utils.clearLocalStorage("userToken");
		store.dispatch({
			type: USER_RESET
		})
		Router.goTo({pathname: webRoutes.home})
	}

	/**
	 * Return user msisdn
	 * @returns {String}
	 */
	static msisdn() {
		try {
			return this.info().msisdn
		} catch (e) {
			return false;
		}
	}


	/**
	 * Return user HE
	 * @returns {String}
	 */
	static isHe() {
		try {
			return this.info().he
		} catch (e) {
			return false;
		}
	}

	/**
	 * Return user info
	 * @returns {Object}
	 */
	static info() {
		try {
			return store.getState().userState.info
		} catch (e) {
			return false;
		}
	}

	/**
	 * Return the correct modal of cancellation
	 */
	static cancel() {
		return User.isContestoneMas() ?
			Router.goToModal({pathname: webRoutes.perfilCancelMasOk, state: {modal: true}})
			:
			Router.goToModal({pathname: webRoutes.perfilCancelUnlimitedOk, state: {modal: true}})
	}

	/**
	 * Checks if user is logged
	 * @returns {boolean}
	 */
	static isLogged() {
		try {
			return !!this.info().msisdn && (this.info().subscription.plan.name !== undefined)
		} catch (e) {
			return false;
		}
	}

	/**
	 * Gets user subscription status
	 * @returns {Promise<void>}
	 */
	static async subscriptionStatus(msisdn){
		return await UserApi.subscriptionInfo(msisdn);
	}

	/**
	 * Subscribe user using args to know the brand type
	 * @param type
	 */
	static async subscribe(type, msisdn, pincode) {
		let brand = null;

		let status = await this.subscriptionStatus(msisdn);
		let subscribed = this.isSubscribed();
		let isUnlimitedTrial = this.isUnlimitedTrial();

		if (!type) {
			brand = planCodes.BRAND_PLAN_FREEMIUM
		} else if (type === planTypes.FREEMIUM){
			brand = planCodes.BRAND_PLAN_FREEMIUM
		} else if  (type === planTypes.UNLIMITED) {
			if (!subscribed && status !== statusTypes.DELETED)
				brand = planCodes.BRAND_PLAN_CONTESTONE_7DIAS
			else
				brand = planCodes.BRAND_PLAN_CONTESTONE
		} else if (type === planTypes.CONTESTONE_MAS) {

			if ((!subscribed && status !== statusTypes.DELETED) || isUnlimitedTrial){
				brand = planCodes.BRAND_PLAN_CONTESTONE_MAS_7DIAS
			} else {
				brand = planCodes.BRAND_PLAN_CONTESTONE_MAS
			}
			
		}

		return (subscribed) ?
			UserApi.upgrade(brand)
		:
			UserApi.prepareSubscribe(brand, msisdn, pincode)
	}

	/**
	 * Checks if user has an account
	 * @returns {boolean}
	 */
	static isSubscribed() {
		try {
			return !!this.info().subscription.plan.name
		} catch (e) {
			return false;
		}
	}

	/**
	 * Checks if user has plan of Freemium type
	 * @returns {boolean}
	 */
	static isFreemium() {
		try {
			return (store.getState().userState.info.subscription.plan.name === appConfig.planTypes.FREEMIUM)
		} catch (e) {
			return false;
		}
	}

	/**
	 * Checks if user has plan of Unlimited type
	 * @returns {boolean}
	 */
	static isUnlimited() {
		try {
			return (
				store.getState().userState.info.subscription.plan.name === appConfig.planTypes.UNLIMITED
			  || store.getState().userState.info.subscription.plan.name === appConfig.planTypes.UNLIMITED7DAYS
			)
		} catch (e) {
			return false;
		}
	}

	/**
	 * Checks if user has plan of Contestone Mas type
	 * @returns {boolean}
	 */
	static isContestoneMas() {
		try {
			return (
				store.getState().userState.info.subscription.plan.name === appConfig.planTypes.CONTESTONE_MAS
				|| store.getState().userState.info.subscription.plan.name === appConfig.planTypes.CONTESTONE_MAS7DAYS
			)
		} catch (e) {
			return false;
		}
	}

	/**
	 * Checks if user has plan of Unlimited Trial type
	 * @returns {boolean}
	 */
	static isUnlimitedTrial() {
		try {
			return (store.getState().userState.info.subscription.plan.name === appConfig.planTypes.UNLIMITED7DAYS)
		} catch (e) {
			return false;
		}
	}

	/**
	 * Checks if user can active more tones
	 * @returns {boolean}
	 */
	static isAbleToActivateTone(queue = 0) {
		try {
			let toneLength = 0
			for (let tb of store.getState().userState.info.toneBoxes)
				toneLength += tb.tones.length
			return this.isFreemium() && ((toneLength + queue) < appConfig.maxTonesFree)
		} catch (e) {
			return true
		}
	}


	/**
	 * Checks if user can active Ringtones
	 * @returns {boolean}
	 */
	static isActivateRingtone(idRingtone = 0) {
		try {
			let ringToneBoxes = store.getState().userState.info.ringToneBoxes
			let isActive = false
			for(let i=0; i < ringToneBoxes.length; i++){
				if(ringToneBoxes[i].idPhonogram === idRingtone){
					isActive = true
					break
				}
			}

			return isActive
		} catch (e) {
			return false
		}
	}

	/**
	 * Checks Ringtones Associated
	 * @returns {boolean}
	 */
	static hasRingtoneAssociated(Tone = 0) {
		let Associated = (Tone['pathRbt'] || Tone['pathTrueTone'])  ? true : false
		return Associated
	}

	/**
	 * Redirect user after login, Subscription or Upgrade
	 */
	static redirect(){
		let callback = store.getState().redirectState.authCallback;
		store.dispatch({ type: START_LOADING })
		
		// Verify Subscription User for Active Tone 
		UserApi.checkSubscriptionCreated().then((res)=>{
			if (callback){
				callback().then(() => {
					Router.goTo({ pathname: webRoutes.tones, state: {refreshUser: true} })
				}).catch((error)=>{
					Router.goToModal({ pathname: webRoutes.errorTone, state:{modal: true} })
				})
			} else {
				Router.goToRoot()
			}
		}).catch((error)=>{
			if(callback){
				Router.goToModal({ pathname: webRoutes.errorTone, state: {modal: true, authCallback: true} })
			} else {
				Router.goToRoot()
			}
		}).finally(()=>{
			store.dispatch({ type: REDIRECT_RESET })
			store.dispatch({ type: STOP_LOADING })
		})
	}
}


export default User