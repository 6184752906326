import React, { memo } from 'react';
import Play from '../../buttons/play';
import Ringtone from '../../buttons/ringtone';
import Contestone from '../../buttons/contestone';

const DefaultHightLight = memo(({ item, index, gaLabel, getBannerImage }) => (
	<React.Fragment>
		<div className="card-tono">
			<div className="container">
				<div className="content">
					<Play Tone={item} Label={gaLabel + ' - ' + (index + 1)} />
					<div className="details">
						<div className="title">{item.name}</div>
						<div className="artist">{item.singer.name}</div>
					</div>
					<div className="actions">
						<Ringtone Tone={item} Label={gaLabel + ' - ' + (index + 1)} />
						<Contestone Tone={item} loadingWhite={true} Label={gaLabel + ' - ' + (index + 1)} />
					</div>
				</div>
				<figure style={{ backgroundImage: 'url(' + getBannerImage(item) + ')' }}></figure>
			</div>
		</div>
	</React.Fragment>
));

export default DefaultHightLight;
