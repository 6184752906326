
//
// Mexico
// ----------------------------------------------------------------------------
module.exports = {
  name: 'mexico',
  ct: 'mx',
  language: 'es',
  page: {
    title: 'Contestone',
  },
  msisdn: {
    countryCode: '52',
    minLength: '10',
    maxLength: '10'
  },
  validateCode: {
    size: 4
  },
  currency: {
    code: 'MXN',
    symbol: '$'
  },
  googlePlay: 'https://play.google.com/store/apps/details?id=com.rbt.android&hl=pt_BR'
}


/*
mexico = {
  store: 'mexico',
  alias: 'mx',
  ct: 'MX',
  page: {
    title: 'Contestone',
    style: 'mexico.less'
  },
  language: {
    code: 'es',
    messages: 'message_es',
  },
  currency: {
    code: 'MXN',
    symbol: '$'
  },
  subscription: {
    doubleOptIn: false,
    freemium: true,
    limitedTonesFreemium: 5
  },
  offer: {
    offerActiveToneInPlayer: true,
  },
  msisdn: {
    countryCode: '52',
    minLength: '10',
    maxLength: '10'
  },
  validateCode: {
    size: 4,
    termsConfirm: false,
    svaClaro: false
  },
  externalAppUrl: {
    linkBannerLoggedIn: true,
    playStore: "https://play.google.com/store/apps/details?id=com.rbt.android"
  },
  footer: {
    logos:[
    {
      id: 1,
      name: 'Contestone',
      img: 'logo-contestone.png',
    },
    {
      id: 2,
      name: 'Telcel',
      img: 'logo-telcel.png'
    }
    ]
  },
  socialConfig: {
    fb_app_id: '1755079521179273',
    share_text: 'Activa ahora como tu Contestone:'
  },
  menuHome: [
    {
      id: 1,
      alias: "home"
    },
    {
      id: 2,
      alias: "genre"
    },
    {
      id: 3,
      alias: "my-tonos"
    },
    {
      id: 4,
      alias: "plans"
    }
  ],
  helpList: [
    {
      id: 1,
      alias: "faq"
    },
    {
      id: 2,
      alias: "terms"
    },
    {
      id: 4,
      alias: "privacy"
    }
  ]
};


module.exports = mexico;*/