import {START_LOADING, STOP_LOADING} from '../actions'

const initialState = {
  isVisible: false,
  count: 0
}

export const loadingReducer = (state = initialState, action) => {
  switch(action.type){
    case START_LOADING:
      return {
        ...state,
        count: state.count+1,
        isVisible: true
      }
    case STOP_LOADING:
      return {
        ...state,
        count: state.count-1,
        isVisible: !!(state.count-1)
      }
    default:
      return state
  }
}