const messages_mx = {
  //Header
  'header': 'Mensagem em ES',

  "BTN_CONFIRM": 'confirmar',
  "BTN_NEXT": 'seguir',

  "HELP": "Ayuda",
  "TERMS_TITLE": "Términos y Condiciones",
  "TERMS_TEXT_1": "Haciendo click aceptas los",

  "SEND_SMS_TITLE": "Ingresa tu número Telcel para que podamos identificarte.",

  "PINCODE_TEXT_1": "¡Un paso más y ya está!",
  "PINCODE_TEXT_2": "Digita el código recibido por SMS.",
  "PINCODE_RESEND_SMS": "Reenviar el código",
  
  "MESSAGE_RESEND_PINCODE": "Te hemos enviado un nuevo código. <br /> Por favor intenta otra vez",

  "ERROR_100006": "Lo siento, tuvimos un error desconocido. Por favor intenta otra vez.",
  "ERROR_200002": "Lo siento, ha añadido un contacto con caracter especial.",
  "ERROR_301009": "Algo está mal, contacta con tu operadora.",
  "ERROR_301012": "Lo siento, tuvimos un error desconocido. Por favor intenta otra vez.",
  "ERROR_301505": "Algo está mal, contacta con tu operadora.",
  "ERROR_309004": "Algo está mal, contacta con tu operadora.",
  "ERROR_309007": "Algo está mal, contacta con tu operadora.",
  "ERROR_309008": "Por favor, aguarde unos instantes su suscripción está en proceso.",
  "ERROR_309115": "Algo está mal, contacta con tu operadora.",
  "ERROR_309116": "Algo está mal, contacta con tu operadora.",
  "ERROR_309126": "Las líneas corporativas no tienen permiso de registro.",
  "ERROR_312004": "Algo está mal, contacta con tu operadora.",
  "ERROR_310010": "Lo siento, tuvimos un error desconocido. Por favor intenta otra vez.",
  "ERROR_310013": "Lo siento, tuvimos un error desconocido. Por favor intenta otra vez.",
  "ERROR_302350": "Algo está mal, contacta con tu operadora.",
  "ERROR_303120": "Vaya, ya alcanzaste tu límite.",
  "ERROR_301010": "Lo siento, tuvimos un error desconocido. Por favor intenta otra vez.",
  "ERROR_INVALID_AUTHORIZATION_CODE": 'El código que ingresaste no es válido. Por favor intenta otra vez.',
  "ERROR_DATA_PROVIDER_ERROR": "Lo siento, tuvimos un error desconocido. Por favor intenta otra vez.",
  "ERROR_SUBSCRIPTION_ALREADY_UPGRADED_TO_THIS_PLAN": "Lo siento, tuvimos un error desconocido. Por favor intenta otra vez.",
  "ERROR_INVALID_TOKEN": "Lo siento, tuvimos un error desconocido. Por favor intenta otra vez.",
  "ERROR": "Lo siento, tuvimos un error desconocido. Por favor intenta otra vez.",
};

export default messages_mx