import React, { useState } from 'react'

import Modal from './modal'
import Router from "../../utils/router";
import Utils from "../../utils/utils";
import UserApi from "../../redux/api/user-api";
import appConfig from "../../application/config";

import ErrorMessage from '../shared/errorMessage'

const PerfilCancelUnlimitedOk = () => {
	const webRoutes = appConfig.web.routes;
	let [errorType, setErrorType] = useState(false)
	const gaEvents = Utils.gaEvents()


	const unsubscribeContestone = () => {
		Utils.gaSend('', gaEvents.Action.CancelarAssinatura, gaEvents.Label.UsuarioIlimitado+' - '+gaEvents.Label.Cancelar)
		UserApi.unsubscribe().then(() => {
			Router.goToModal({pathname: webRoutes.perfilCancelUnlimited, state: {modal: true}})
		}).catch((error)=>{
			setErrorType(error)
		})
	}
	
	return (
		<Modal
			bg={''}
			type={'3'}
			AreaName={gaEvents.Action.CancelarAssinatura}
			Label={gaEvents.Label.UsuarioIlimitado}
			content={
				<>
					<p className='font-weight-bold'>Al cancelar tu suscripción perderás los tonos que escucha quien te llama</p>
					<p>¿Seguro deseas continuar?</p>
					<ErrorMessage type={errorType} resetErrorType={setErrorType} />
					<div className="buttons">
						<div className="content">
							<div className="button type-1">
								<span onClick={unsubscribeContestone}>
									confirmar
								</span>
							</div>
						</div>
					</div>
				</>
			}
		/>
	)
}

export default PerfilCancelUnlimitedOk


