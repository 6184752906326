import axios from 'axios';

import appConfig from '../application/config';
import Utils from '../utils/utils';
import LoadingApi from '../redux/api/loading-api';

class DBModel {
  constructor(deleteCtHeader, withoutLoading) {
    this.ApiUrl = Utils.getApiUrl();
    this.TimeOut = appConfig.apiConnection.timeout;
    this.loading = !withoutLoading;
    const store_ct = Utils.getStore().ct;

    let instance = axios.create({
      baseURL: this.ApiUrl,
      timeout: this.TimeOut,
      headers: { 'ct': store_ct }
    });

    if (deleteCtHeader) {
      delete instance.defaults.headers['ct'];
    }

    instance.interceptors.request.use(
      config => {
        if (appConfig.apiConnection.dbModelDebug.request) //console.debug("requested", config);

          if (this.loading) {
            LoadingApi.startLoading();
          }

        return config;
      }, error => {
        if (appConfig.apiConnection.dbModelDebug.request) //console.debug("not requested", error);
          return Promise.reject(error);
      }
    );

    instance.interceptors.response.use(
      response => {
        if (appConfig.apiConnection.dbModelDebug.response) //console.debug("success", response);
          if (this.loading) {
            LoadingApi.stopLoading();
          }
        return response;
      }, error => {
        if (appConfig.apiConnection.dbModelDebug.response) //console.debug("error", error.response);

          if (this.loading) {
            LoadingApi.stopLoading();
          }

        /**
         * TODO
         * QUANDO O ERRO FOR 401 (NOT AUTHORIZED) REDIROCINA PARA HOME
         * INSERIR OUTROS ERROR_HANDLERS AQUI!
         */
        /*if (error.response.status === 401){
          //User.clearData();
          //return Promise.reject(browserHistory.push(appConfig.web.routes.home));
        }*/

        return Promise.reject(error);
      }
    );
    return instance;
  }

}

export default DBModel;
