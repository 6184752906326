import React from 'react';
import Router from '../../utils/router';

import Modal from './modal';
import appConfig from '../../application/config';

const DeleteDataConfirmationError = (props) => {
	const webRoutes = appConfig.web.routes;

	return (
		<Modal
			bg={''}
			type={'3'}
			content={
                <>
					<figure className='logotype'/>
					<p className='font-weight-bold'>Hubo un error</p>
                    <p>No pudimos completar tu solicitud debido a un error del sistema. Haz clic en el botón para intentarlo nuevamente.</p>
                    <div className="button-container">
                    	<div className="button type-2" onClick={() => Router.goTo({pathname: webRoutes.deleteData})}>
                    	    <span>volver</span>
                    	</div>
                    </div>
				</>
			}
		/>
	);
};

export default DeleteDataConfirmationError;


