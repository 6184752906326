import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import appConfig from '../../application/config'
import Router from '../../utils/router'
import User from '../../utils/user'
import Utils from '../../utils/utils'

const Ringtone = (props) => {
	const webRoutes = appConfig.web.routes
	let isActive = User.isActivateRingtone(props.Tone.id)
	let hasRigtoneAssociated = User.hasRingtoneAssociated(props.Tone)

	const handleClick = ()=>{
		Utils.gaSend('', Utils.gaEvents().Action.Ringtone, props.Label)
		Utils.openContestoneMas(Utils.gaEvents().Action.Ringtone + ' - ' +props.Label)
	}

	return (
		<a href="true" className={"button type-3 ringtone" + (User.hasRingtoneAssociated(props.Tone) ? '': ' disabled')} onClick={(e)=>{e.preventDefault(); handleClick()}}>
			<div className={"icon icon-ringtone-" + (User.isActivateRingtone(props.Tone.id) ? "on": "off")}></div>
			<span>Ringtone</span>
		</a>
	)
}

export default Ringtone