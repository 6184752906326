import store from '../../store';
import Router from "../../../utils/router";
import appConfig from '../../../application/config'
import UserUtils from "../../../utils/user";
import LandingApi from "../landing-api";

class Type4 {
	static firstStep() {
		const landingState = store.getState().landingState;

		// Users with any kind of plan will activate tone if toneId was passed as url parameter
		// and be redirected to end of the flux
		if (!!landingState.userPlan) {
			return Router.goTo({pathname: appConfig.web.routes.landing.redirect});
		} else {
			// Users without of plan will be redirected to the freemium subscribe page
			LandingApi.setScreen("freemium");
			let landingScreen = (UserUtils.isHe()) ? "he" : "pincode";
			return Router.goTo({pathname: appConfig.web.routes.landing[landingScreen]});
		}
	}
}

export default Type4;