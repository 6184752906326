import { SET_EDITORIAL_HELP, SET_EDITORIAL_TERMS, SET_EDITORIAL_RULES } from '../actions'

const initialState = {
  help: "",
  terms: "",
  rules: "",
};

export const editorialReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EDITORIAL_HELP:
      return {
        ...state,
        help: action.help
      };
    case SET_EDITORIAL_TERMS:
      return {
        ...state,
        terms: action.terms
      };
    case SET_EDITORIAL_RULES:
      return {
        ...state,
        rules: action.rules
      };
    default:
      return state;
  }
};