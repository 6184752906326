import React, { useEffect } from 'react';
import { useSelector } from 'react-redux'
import CatalogApi from '../../redux/api/catalog-api';
import CardCategory from '../shared/card-category';
import Modal from './modal'
import Utils from '../../utils/utils'

const Categories = () => {
	let categoryList = useSelector(store => store.catalogState.categoryList)

	useEffect(() => {
		CatalogApi.getCategoryList()
		Utils.gaSend('', Utils.gaEvents().Action.Categoria, Utils.gaEvents().Label.AcessoACategorias)
	}, [])

	return (
		<Modal
			type={'4'}
			AreaName={Utils.gaEvents().Action.Categoria}
			content={
				<>
					<section className="categories">
						{categoryList && categoryList.map((item, index) =>
							<CardCategory item={item} key={index} />
						)}
					</section>
				</>
			}
		/>
	);
}

export default Categories