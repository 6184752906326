import store from '../store';
import * as types from '../actions';

class TooltipApi {

  static Search(state) {
		store.dispatch({
			type: types.TOOLTIP_SEARCH,
			state
		});
	}

	static tone(state) {
		store.dispatch({
			type: types.TOOLTIP_TONE,
			state
		});
	}

	static toneReset(state) {
		store.dispatch({
			type: types.TOOLTIP_TONE_RESET
		});
	}
}

export default TooltipApi;



