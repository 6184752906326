import {IS_PLAYING, PLAY_PREVIEW, STOP_PREVIEW, LOADING_PREVIEW} from '../actions'

const initialState = {
  isPlaying: false,
  loading: false,
  infoPreview: false
}

export const previewReducer = (state = initialState, action) => {
  switch(action.type){
    case IS_PLAYING:
      return {
        ...state,
        isPlaying: action.state
      }
    case PLAY_PREVIEW:
      return {
        ...state,
        infoPreview: action.state
      }
    case STOP_PREVIEW:
      return {
        ...state,
        infoPreview: false,
        isPlaying: false
      }
    case LOADING_PREVIEW:
        return {
          ...state,
          loading: action.state
        }
    default:
      return state
  }
}