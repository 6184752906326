import React, {useState, useEffect, useRef} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import appConfig from '../../application/config.js';

import User from '../../utils/user.js';
import Router from '../../utils/router.js';

const deleteData = () => {
	const webRoutes = appConfig.web.routes;

	const openConfirmationModal = () => {
		if(!User.isLogged()) {
			Router.goToModal({pathname: webRoutes.auth, state: {modal: true, deletePersonalData: true}});
		} else {
			Router.goToModal({pathname: webRoutes.deleteDataConfirmation, state: {modal: true}});
		}
	};

	return (
		<>
			<section className="perfil">
				<section className="identifier">
					<div className="content">
						<div className="title-container">
							<h2>Eliminar datos personales</h2>
						</div>
						<div className="text-container">
							<p className="text">Serás desconectado de Contestone</p>
							<p className="text">Tu suscripción, cuenta y datos personales se eliminarán permanentemente de nuestra base de dados.</p>
							<p className="text-alert"><b>Atención:</b></p>
							<p className="text">Esta acción no se puede deshacer!</p>
						</div>
						<div className="button-container">
							<div className="button type-2" onClick={() => openConfirmationModal() }>
								<span>si, eliminar</span>
							</div>
							<div className="button type-back-horizontal" onClick={() => Router.goTo({pathname: webRoutes.home})}>
								<span>no, rechazar</span>
							</div>
						</div>
					</div>
				</section>
				<div className="stripe"></div>
			</section>
		</>
	);
};

export default deleteData;


