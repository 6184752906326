import React from 'react';
import { useDispatch } from 'react-redux'

import { NavLink } from 'react-router-dom';
import appConfig from '../../application/config';

const CardCategory = (props) => {
	const webRoutes = appConfig.web.routes;
	const dispatch = useDispatch()

	const setColorStripeCategory = (color)=>{
		dispatch({type:'COLOR_STRIPE_CATEGORY', state: color})
	}

	return (
		<>
			<div className="card-category">
				<div className="container" style={{ backgroundColor: props.item.color }}>
					<NavLink to={{pathname: webRoutes.category + '/' + props.item.alias }} key={props.item.id} onClick={()=>setColorStripeCategory(props.item.color)}>
						{props.item.name}
					</NavLink>
				</div>
			</div>
		</>
	)
}

export default CardCategory