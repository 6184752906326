import store from '../store';
import * as types from '../actions';

import dbModel from '../../models/DB.models';
import appConfig from '../../application/config';

import User from '../../utils/user';
import Router from '../../utils/router';

import Type from './landing/type';

class LandingApi {

	/**
	 * Get user plan and set in store
	 * @returns {Q.Promise<any> | * | undefined}
	 */
	static setUserPlan(msisdn) {
		const DB = new dbModel(false, false);
		return DB({
			method: 'post',
			url: appConfig.apiConnection.paths.User.SubscriptionInfo,
			data: { msisdn }
		}).then(res => {
			const userPlan = res.data.plan.name;
			store.dispatch({
				type: types.LANDING_USER_PLAN,
				userPlan
			});
		}).catch((error) => {
			console.error(error);
		});
	}

	/**
	 * Set url params in store
	 * @returns {Q.Promise<any> | * | undefined}
	 */
	static setParamsData(data) {
		store.dispatch({
			type: types.LANDING_DATA,
			...data
		});
	}

	static setError(data) {
		store.dispatch({
			type: types.LANDING_ERROR,
			error: data
		});
	}

	static setMsisdn(data) {
		store.dispatch({
			type: types.LANDING_MSISDN,
			msisdn: data
		});
	}

	static setScreen(data) {
		store.dispatch({
			type: types.LANDING_SCREEN,
			screen: data
		});
	}

	/**
	 * Begin flux based on type variable
	 */
	static beginFlux(msisdn) {
		const type = store.getState().landingState.typeId;
		LandingApi.setUserPlan(msisdn).finally(() => {
			if (!!Type['Type' + type]) {
				Type['Type' + type].firstStep();
			} else {
				Router.goTo({ pathname: appConfig.web.routes.landing.redirect });
			}
		});
	}

	/**
	 * add tone to an msisdn
	 * @param toneId
	 * @returns {*}
	 */
	static addTone(toneId) {
		const msisdn = User.msisdn();
		const DB = new dbModel(false, true);
		//const UserToken = Utils.getLocalStorage('userToken');

		return DB({
			method: 'post',
			url: appConfig.apiConnection.paths.Tone.Add,
			//headers: {UserToken},
			data: {
				msisdn,
				toneBoxType: appConfig.toneBoxTypes.DEFAULT,
				tones: [{ id: toneId }]
			}
		});
	}

	static isContestoneMas() {
		const userPlan = store.getState().landingState.userPlan;
		return userPlan === appConfig.planTypes.CONTESTONE_MAS || userPlan === appConfig.planTypes.CONTESTONE_MAS7DAYS;
	}

	static isUnlimited() {
		const userPlan = store.getState().landingState.userPlan;
		return userPlan === appConfig.planTypes.UNLIMITED || userPlan === appConfig.planTypes.UNLIMITED7DAYS;
	}

	static isFreemium() {
		const userPlan = store.getState().landingState.userPlan;
		return userPlan === appConfig.planTypes.FREEMIUM;
	}
}

export default LandingApi;



