import React from 'react';
import Router from '../../utils/router';

import Modal from './modal';
import appConfig from '../../application/config';
import UserApi from '../../redux/api/user-api';


const DeleteDataConfirmation = (props) => {
	const webRoutes = appConfig.web.routes;

	const deletePersonalData = async () => {
		return UserApi.deletePersonalData().then((res) => {
			Router.goToModal({pathname: webRoutes.deleteDataConfirmationSuccess, state: {modal: true}});
		}).catch((error) => {
			Router.goToModal({pathname: webRoutes.deleteDataConfirmationError, state: {modal: true}});
		});
	};

	return (
		<Modal
			bg={''}
			type={'3'}
			content={
                <>
					<figure className='logotype'/>
					<p className='font-weight-bold'>Seguro que quieres eliminar definitivamente tu subscripción, cuenta y datos personales?</p>
                    <p><b>Atención:</b> Esta acción no se puede desharcer!</p>
                    <div className="button-container">
                    	<div className="button type-2" onClick={() => deletePersonalData() }>
                    	    <span>si, eliminar</span>
                    	</div>
					    <div className="button type-back-vertical" onClick={() => Router.goTo({pathname: webRoutes.home})}>
					    	<span>no, rechazar</span>
					    </div>
                    </div>
				</>
			}
		/>
	);
};

export default DeleteDataConfirmation;


