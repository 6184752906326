import React, {useState} from 'react';
import appConfig from '../../application/config';

import logotype from '../../assets/svg/logotype-contestone.svg';
import UserApi from '../../redux/api/user-api';
import UserUtils from '../../utils/user';
import Router from '../../utils/router';
import {useSelector} from 'react-redux';
import LandingApi from '../../redux/api/landing-api';

const LandingPincode = (props) => {
	const msisdn = useSelector(state => state.landingState.msisdn);
	const screen = useSelector(state => state.landingState.screen);
	const store = useSelector(state => state.storeState.config);

	const planTypes = appConfig.planTypes;
	const webRoutes = appConfig.web.routes;
	const texts = appConfig.landing.texts[screen];

	let [pincode, setPincode] = useState('');
	const pincodeLength = store.validateCode.size;

	const handleChange = (e) => {
		let value = e.target.value.replace(/[^\d]+/, '');
		setPincode(value);
		e.target.value = value;
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter')
			doSubscribe();
	};

	const doSubscribe = () => {
		let plan = (screen === 'mas') ? planTypes.CONTESTONE_MAS :
			(screen === 'unlimited') ? planTypes.UNLIMITED :
				planTypes.FREEMIUM;
		UserApi.login(msisdn, pincode).finally(() => {
			UserUtils.subscribe(plan, msisdn, pincode).then(() => {
				Router.goTo({pathname: webRoutes.landing.redirect});
			}).catch(e => {
				LandingApi.setError('código invalido');
				Router.goTo({pathname: webRoutes.landing.error});
			});
		});
	};

	const sendSms = () => {
		return UserApi.sendSmsCode(msisdn);
	};

	return (
		<>
			<section className={'wifi-sign-' + screen}>
				<figure className="logotype">
					<img src={logotype} alt=""/>
				</figure>
				<h1 dangerouslySetInnerHTML={{__html: texts.h1}}/>
				{texts.h2 && <h2 dangerouslySetInnerHTML={{__html: texts.h2}}/>}
				<input type="number" placeholder="Digita el código recebido por SMS..."
				       onChange={handleChange} onKeyPress={handleKeyPress} maxLength={pincodeLength}/>
				<a href="#" onClick={sendSms} className="link">Reenviar el código</a>
				<div className="button type-4" onClick={doSubscribe}>
					<span>{texts.button}</span>
				</div>
				{texts.description &&
				<p className="condition" dangerouslySetInnerHTML={{__html: texts.description}}/>
				}
			</section>
		</>
	);
};

export default LandingPincode;


