import React, {useEffect} from 'react'
import Modal from './modal'

import User from "../../utils/user";
import ButtonGooglePlay from '../buttons/button-googleplay'

const UpsellRbtOk = () => {
	useEffect(() => {
		setTimeout(User.redirect, 3000)
	},[])
	return (
		<Modal
			bg={''}
			type={'3'}
			hideClose={true}
			content={
				<>
					<p className='font-weight-bold'>¡Bienvenido a Contestone Ilimitado!</p>
					<p>Descarga el app para administrar tus tonos</p>
					<ButtonGooglePlay/>
				</>
			}
		/>
	)
}

export default UpsellRbtOk


