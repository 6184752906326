import store from "../../store";
import UserUtils from "../../../utils/user";
import LandingApi from "../landing-api";
import Router from "../../../utils/router";
import appConfig from "../../../application/config";
import Utils from "../../../utils/utils";


class Type6 {
	static firstStep(){
		const landingState = store.getState().landingState;

		let landingScreen = (UserUtils.isHe()) ? "he" : "pincode";

		// Contestone mas or Unlimited user
		if (!!landingState.userPlan && (LandingApi.isContestoneMas() || LandingApi.isUnlimited())) {
			return Router.goTo({pathname: appConfig.web.routes.landing.redirect});
		} else if (!!landingState.userPlan && LandingApi.isFreemium() && Utils.isAndroid()) {
			// Freemium user or not user
			LandingApi.setScreen("mas");
		} else {
			LandingApi.setScreen("unlimited");
		}
		return Router.goTo({pathname: appConfig.web.routes.landing[landingScreen]});
	}
}

export default Type6;