import React, { useEffect, useState, useCallback } from 'react';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';

import Tones from './components/pages/tones';
import Perfil from './components/pages/perfil';
import Landing from './components/pages/landing';
import Category from './components/pages/category';
import deleteData from './components/pages/delete';

import appConfig from './application/config';
import Utils from './utils/utils';

function AppRouter(props) {
	const history = useHistory();
	const location = useLocation();
	let [previousLocation, setPreviousLocation] = useState('');

	const webRoutes = appConfig.web.routes;

	let isModal = (
		location.state &&
		location.state.modal &&
		previousLocation &&
		previousLocation.pathname !== location.pathname
	);

	const checkIfPathIsAble = useCallback(() => {
		let path = location.pathname.split('/');
		return appConfig.web.modalsRouter.indexOf(path[1]) === -1;
	}, [location.pathname]);

	useEffect(() => {
		if (checkIfPathIsAble()) {
			let basePath = null;
			try { basePath = history.location.state.basePath; } catch (e) { }
			if (basePath)
				setPreviousLocation({ ...location, pathname: basePath });
			else
				setPreviousLocation(location);
			Utils.setLocalStorage('linkRoot', (basePath) ? basePath : location.pathname);
		}
		Utils.setTemporaryClass('fade', 100, 'main');
		Utils.gaPageview(location.pathname);
	}, [location, history, checkIfPathIsAble]);

	return (
		<Switch location={isModal ? previousLocation : location}>
			<Route exact path="/" render={() => (
				<Redirect to={webRoutes.tones} />
			)} />

			<Route path={webRoutes.tones + '/:Tab?'} component={Tones} />
			<Route path={webRoutes.perfil} component={Perfil} />
			<Route path={webRoutes.landing.main + '/:partnerId?/:type?/:toneId?'} component={Landing} />
			<Route path={webRoutes.category + '/:Alias'} component={Category} />

			<Route path={webRoutes.deleteData} component={deleteData} />

			<Route path={webRoutes.terms} component={Tones} />
			<Route path={webRoutes.help} component={Tones} />
			<Route path={webRoutes.rules} component={Tones} />

			<Route path="*">
				<Redirect to={webRoutes.tones} />
			</Route>
		</Switch>
	);
}

export default AppRouter;
