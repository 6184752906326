import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

import Modal from './modal'
import Router from "../../utils/router";
import appConfig from "../../application/config";

const ErrorTone = () => {
	const webRoutes = appConfig.web.routes;
	const location = useLocation()

	useEffect(() => {
		setTimeout(()=>{
			if(location.state && location.state.authCallback){
				Router.goTo({pathname: webRoutes.perfil})
			} else {
				Router.goToRoot()
			}
		}, 5000)
	},[location])

	return (
		<Modal
			bg={''}
			type={'3'}
			hideClose={true}
			content={
				<>
					<p><strong>¡Baya!<br />¡Aún no puedes activar tonos!</strong><br/><br/>
					Espera un poco más mientras estamos creando tu cuenta.<br/><br/>
					¡Inténtalo de nuevo en un momento!</p>
				</>
			}
		/>
	)
}

export default ErrorTone


