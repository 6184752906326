import store from '../../store';
import UserUtils from '../../../utils/user';
import LandingApi from '../landing-api';
import Router from '../../../utils/router';
import appConfig from '../../../application/config';
import Utils from '../../../utils/utils';


class Type1 {
	static firstStep(){
		const landingState = store.getState().landingState;

		let landingScreen = (UserUtils.isHe()) ? 'he' : 'pincode';

		if (!Utils.isAndroid() || (!!landingState.userPlan && LandingApi.isContestoneMas())) {
			return Router.goTo({pathname: appConfig.web.routes.landing.redirect});
		} else {
			// Freemium user or not user
			LandingApi.setScreen('mas');
		}
		return Router.goTo({pathname: appConfig.web.routes.landing[landingScreen]});
	}
}

export default Type1;
