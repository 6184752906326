import React, {useState, useEffect, useRef} from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import appConfig from '../../application/config';

import Collapse from '../shared/collapse.jsx';
import CardTone from '../shared/card-tone.jsx';

import TonesList from '../shared/tones-list'
import ArtistList from '../shared/artist-list'

import User from '../../utils/user';
import Utils from '../../utils/utils';
import Router from '../../utils/router';
import {useSelector} from "react-redux";

import UserApi from '../../redux/api/user-api';
import ColorApi from '../../redux/api/color-api'

import ButtonGooglePlay from '../buttons/button-googleplay'

const Perfil = () => {
	const webRoutes = appConfig.web.routes;
	
	const user = useSelector(state => state.userState)
	const store = useSelector(state => state.storeState)
	const location = useLocation()

	const userName = useRef(User.info().name)
	const [tones, setTones] = useState([])
	const [view, setView] = useState("TONOS")
	const [plan, setPlan] = useState(User.info().subscription.plan)
	const [savingInput, setSavingInput] = useState(false)
	const [disableInput, setDisableInput] = useState(true)

	useEffect(() => {
		ColorApi.setColorStripe('#1CBFD4')
		userName.current.value = User.info().name ? User.info().name : "identificate"
		setPlan(User.info().subscription.plan)
		let toneList = [];
		for (let tb of User.info().toneBoxes)
			if (tb.toneBoxType === appConfig.toneBoxTypes.DEFAULT) {
				for (let t of tb.tones)
					if (!toneList.find(tone => tone.id === t.id))
						toneList.push(t)
			}
		setTones(toneList)
	}, [user])

	useEffect(() => {
		if(location.state){
			if(location.state.refreshUser){
				UserApi.info()
			}

			if(location.state.openModal){
				openModal(location.state.openModal.type)
			}
		}

	}, [location])

	const openModal = (type) => {
		Router.goToModal({pathname: type, state: {modal: true}})
	}

	const preClick = () => {
		if(!User.isLogged()){
			Router.goToModal({pathname: webRoutes.auth, state: {modal: true}})
			return Promise.reject();
		} else if (!User.isSubscribed()) {
			Router.goToModal({pathname: webRoutes.welcomeFreemium, state: {modal: true}})
			return Promise.reject();
		} else {
			return Promise.resolve();
		}
	}

	const openGoogle = (_label) =>{
		if(!User.isLogged()){
			Router.goToModal({pathname: webRoutes.auth, state: {modal: true}})
		} else {
			Router.goToModal({pathname: webRoutes.googleplay, state: {modal: true, label: _label}})
		}
		return Promise.resolve()
	}

	const editUser = () => {
		if (disableInput) {
			Utils.gaSend('', Utils.gaEvents().Action.Perfil, Utils.gaEvents().Label.EditarNome)
			preClick().then(() => {
				setDisableInput(false)
			})
		} else {
			setSavingInput(true)
			UserApi.edit(User.msisdn(), userName.current.value).then(() => {
				setDisableInput(true)
			}).catch(() => {
				userName.current.value = User.info().name
			}).finally(() => {
				setSavingInput(false)
			})
		}
	}

	return (
		<>
			<section className={"perfil " + (!User.isSubscribed() ? "anonymous" : "")}>

				<section className="identifier">

					<div className="content">
						<div className="line">

							<div className="column">
								<div className="first">
									<figure className="user-image"/>
									<div className="second">
										<div className="user">
											<input disabled={disableInput} type="text" placeholder='Nome do usuário' ref={userName}/>
											<div className="button type-link" onClick={editUser}>
												{disableInput ?
													<div className="icon icon-edit"/>
													:
													(savingInput ?
														<div className="icon icon-loading"/>
														:
														<div className="icon icon-check"/>
													)
												}
											</div>
										</div>
										<div className="phone">
											{User.msisdn()}
										</div>
									</div>
								</div>
								<NavLink replace to={{pathname: webRoutes.perfilExit, state: {modal: true, label: Utils.gaEvents().Label.EncerrarSessao}}}>
									Cerrar sesión
								</NavLink>
							</div>

							<div className="column">
								<table>
									<tbody>
									<tr>
										<td>Plan</td>
										<td>{plan.name}</td>
									</tr>
									<tr>
										<td>Valor</td>
										<td>{store.config.currency.symbol + " " + plan.price + " (IVA Incluido)"}</td>
									</tr>
									<tr>
										<td>Periodicidad</td>
										<td>{plan.periodicity}</td>
									</tr>
									<tr>
										<td>Suscripción</td>
										<td>{User.info().subscription.status === "CREATED" ? "ACTIVA" : "EN ACTIVACIÓN"}</td>
									</tr>
									</tbody>
								</table>
								{!User.isFreemium() && 
									<a href="javascript:;" className="cancel" onClick={User.cancel}>Cancelar suscripción</a>
								}
							</div>

							<div className="column">
								<div className="area" onClick={() => Utils.openContestoneMas(Utils.gaEvents().Label.ImagemDoGooglePlay)}>
									<p>Descarga ahora el app y elige los tonos que escuchan tus contactos y disfruta lo mejor de <span className="font-weight-bold">Contestone</span>.</p>
								</div>
								<ButtonGooglePlay/>
							</div>

							<div className="column anonymous">
								<p className="font-weight-bold">¡Vaya!<br />Parece que aún no has iniciado sesión en Contestone!</p>
								<p>¿Qué tal conectarse ahora?<br />¡Disfrutarás de los mejores tonos para ti y para los que te llaman!</p>
								<div className="button type-2" onClick={() => {Router.goToModal({pathname: webRoutes.auth, state: {modal: true}})}}>
									<span>iniciar sesión</span>
								</div>
							</div>

						</div>
					</div>

				</section>

				<section className="itens">

					<div className="content-collapse">

						{/* PLAN */}
						<Collapse blockClick={preClick}
								  icon={"icon-suscripcion"}
								  caption={"Suscripción"}
						>
							<div className="content-plan">
								<div className="logotype"/>
								{!User.isFreemium() ?

									<table>
										<tbody>
										<tr>
											<td>Plan</td>
											<td>{plan.name}</td>
										</tr>
										<tr>
											<td>Valor</td>
											<td>{store.config.currency.symbol + " " + plan.price + " (IVA Incluido)"}</td>
										</tr>
										<tr>
											<td>Periodicidad</td>
											<td>{plan.periodicity}</td>
										</tr>
										<tr>
											<td>Suscripción</td>
											<td>{User.info().subscription.status === "CREATED" ? "ACTIVA" : "EN ACTIVACIÓN"}</td>
										</tr>
										</tbody>
									</table>
									:
									<>
										<p className='text-align-center font-weight-bold'>Estás disfrutando de Contestone Gratis</p>
										<p className='text-align-center'>Puedes activar hasta 5 tonos de la sección Gratis.</p>
									</>
								}
								{!User.isFreemium() && 
								<p>
									<span className='link'>
										<a href="javascript:;" onClick={User.cancel}>
											Cancelar suscripción
										</a>
									</span>
								</p>
								}
							</div>
						</Collapse>

						{/* TONES */}
						<Collapse blockClick={preClick}
								  icon={"icon-tonos"}
								  caption={"Mis Tonos"}
						>
							{!!tones.length ? (
								tones.map((item, key) =>
									<CardTone Tone={item} key={item.id}/>
								)
							):(
								<p className="no-items">Sin tonos</p>
							)
							}
						</Collapse>

						{/* SUBSCRIPTION */}
						<Collapse blockClick={openGoogle}
								  icon={"icon-users"}
									caption={"Mis Contactos"}
									label={Utils.gaEvents().Label.MeusContatos}
						/>

						{/* HELP */}
						<NavLink replace className={'item'} to={{pathname: webRoutes.help, state: {modal: true}}}>
							<div className="container">
								<div className="button type-link">
									<div className="icon icon-help"/>
								</div>
								<span>Ayuda</span>
								<div className="button type-link">
									<div className="icon icon-back"/>
								</div>
							</div>
						</NavLink>

						{/* TERMS */}
						<NavLink replace className={'item'} to={{pathname: webRoutes.terms, state: {modal: true}}}>
							<div className="container">
								<div className="button type-link">
									<div className="icon icon-list"/>
								</div>
								<span>Términos y Condiciones</span>
								<div className="button type-link">
									<div className="icon icon-back"/>
								</div>
							</div>
						</NavLink>

						{/* CLOSE SESSION */}
						{User.isSubscribed() ?
							<div className="item">
								<div className="container">
									<div className="button type-link">
										<div className="icon icon-cancel"/>
									</div>
									<span onClick={() => Router.goToModal({pathname: webRoutes.perfilExit, state: {modal: true, label: Utils.gaEvents().Label.EncerrarSessao}})}>
										Cerrar Sesión
									</span>
								</div>
							</div>

							:

							<div className="item">
								<div className="container">
									<div className="button type-link">
										<div className="icon icon-login"/>
									</div>
									<span onClick={() => Router.goToModal({pathname: webRoutes.auth, state: {modal: true}})}>
										Iniciar Sesión
									</span>
								</div>
							</div>
						}

					</div>

					<div className="content-tonos-contact">

						<header>
							<div className="switch">
								<div className={'button type-switch ' + (view==='TONOS'?'active':'')} onClick={()=>setView('TONOS')}>
									<span>{'mis tonos'}</span>
								</div>
								{/* <div className={'button type-switch ' + (view==='ARTISTS'?'active':'')} onClick={()=>setView('ARTISTS')}>
									<span>{'mis contactos'}</span>
								</div> */}
								<NavLink replace className="button type-switch" to={{ pathname: webRoutes.googleplay, state: { modal: true, label: Utils.gaEvents().Label.MeusContatos } }}>
									<span>{'mis contactos'}</span>
								</NavLink>
							</div>
						</header>

						{(view === 'TONOS') ?(
							<>
								<TonesList List={tones} noItems={(!tones.length) && "Sin tonos"} AreaName={'Perfil'} />
							</>
						):(
							<ArtistList List={[]} />
						)}

					</div>

				</section>

				<div className="stripe"></div>

			</section>
		</>
	)
}

export default Perfil


