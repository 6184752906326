import React from 'react';
import appConfig from '../../application/config';

import logotype from '../../assets/svg/logotype-contestone.svg';
import UserApi from "../../redux/api/user-api";
import Router from "../../utils/router";
import UserUtils from "../../utils/user";
import {useSelector} from "react-redux";
import LandingApi from "../../redux/api/landing-api";

const LandingHe = () => {
	const planTypes = appConfig.planTypes;
	const webRoutes = appConfig.web.routes;
	const screen = useSelector(state => state.landingState.screen);
	const texts = appConfig.landing.texts[screen];

	const doSubscribe = () => {
		let plan = (screen === "mas") ? planTypes.CONTESTONE_MAS :
			(screen === "unlimited") ? planTypes.UNLIMITED :
				planTypes.FREEMIUM;
		UserApi.he().then(data => {
			UserApi.login(data.msisdn, data.validateCode).finally(() => {
				UserUtils.subscribe(plan, data.msisdn, data.validateCode).then(() => {
					Router.goTo({pathname: webRoutes.landing.redirect})
				}).catch(e => {
					LandingApi.setError(e.message || e);
					Router.goTo({pathname: webRoutes.landing.error})
				});
			});
		});
	};

	return (
		<div className={"he-sign-" + screen}>
			<figure className="logotype">
				<img src={logotype} alt=""/>
			</figure>
			<h1 dangerouslySetInnerHTML={{__html: texts.h1}}/>
			{texts.h2 && <h2 dangerouslySetInnerHTML={{__html: texts.h2}}/>}
			<div className="button type-4" onClick={doSubscribe}>
				<span>{texts.button}</span>
			</div>
			<p className="condition" dangerouslySetInnerHTML={{__html: texts.description}}/>
		</div>
	)
}

export default LandingHe;


