import store from '../store';
import * as types from '../actions';

import dbModel from '../../models/DB.models';
import appConfig from '../../application/config';

class EditorialApi {
	static getTerms() {
		return (new dbModel())({
			method: 'get',
			url: appConfig.apiConnection.paths.Editorial.Terms
		}).then(res => {
			store.dispatch({
				type: types.SET_EDITORIAL_TERMS,
				terms: res.data
			});
		}).catch((error) => {
			console.error(error);
		});
	}

	static getHelp() {
		return (new dbModel())({
			method: 'get',
			url: appConfig.apiConnection.paths.Editorial.Help
		}).then(res => {
			store.dispatch({
				type: types.SET_EDITORIAL_HELP,
				help: res.data.questions
			});
		}).catch((error) => {
			console.error(error);
		});
	}

	static setRules(rules) {
		store.dispatch({
			type: types.SET_EDITORIAL_RULES,
			rules
		});
	}
}

export default EditorialApi;



