import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import Modal from './modal'
import User from "../../utils/user";
import Utils from "../../utils/utils";
import Router from '../../utils/router'
import appConfig from "../../application/config";

import ErrorMessage from '../shared/errorMessage'
import ButtonGooglePlay from '../buttons/button-googleplay'

const ContestoneMas = () => {
	let history = useHistory()
	let [errorType, setErrorType] = useState(false)
	
	const webRoutes = appConfig.web.routes
	const Subscribe = history.location.state ? history.location.state.subscribe : false

	const handleClick = () => {
		Utils.gaSend('', Utils.gaEvents().Action.UpSelling, Utils.gaEvents().Label.ContestoneMas+' - '+Utils.gaEvents().Label.Assinar)
		if(Subscribe){
			User.subscribe(appConfig.planTypes.CONTESTONE_MAS).then((res)=>{
				Router.goTo({pathname: webRoutes.tones, state: {openModal: {type: webRoutes.contestoneMasOk } }})
			}).catch((error)=>{
				setErrorType(error)
			})
		} else {
			Router.goToModal({pathname: webRoutes.auth, state: {modal: true, subscribeContestoneMas: true}})
		}
	}

	return (
		<Modal
			bg={'bg-graph-1'}
			type={'6'}
			AreaName={Utils.gaEvents().Action.UpSelling}
			Label={Utils.gaEvents().Label.ContestoneMas}
			content={
				<>
					<figure className='logotype'/>
					<p>Suscríbete para activar tonos<br/>para tu celular y administra tus ringtones desde el aplicativo. <br /> $9.99 semanal IVA incluido.</p>
					<ErrorMessage type={errorType} resetErrorType={setErrorType} />
					<div className="buttons">
						<div className="button type-1">
							<span onClick={handleClick}>
								subscribir
							</span>
						</div>
					</div>
					<ButtonGooglePlay/>
				</>
			}
		/>
	)
}

export default ContestoneMas


