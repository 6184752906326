module.exports = {
	Category: {
		NaoLogado: 'Não Logado',
		Logado: 'Logado',
	},
	Action: {
		Error: 'Error',
		UpSelling: 'Up Selling',
		DesativarTono: 'Desativar Tono',
		AtivarTono: 'Ativar Tono',
		Perfil: 'Perfil',
		EditarTono: 'Editar Tono',
		CancelarAssinatura: 'Cancelar Assinatura',
		Login: 'Login',
		PlayPreview: 'Play Preview',
		Search: 'Search',
		Termos: 'Termos e condições',
		Ajuda: 'Ajuda',
		Rules: 'Regras',
		GooglePlay: 'Google Play',
		Ringtone: 'Ringtone',
		Categoria: 'Categoria',
	},
	Label: {
		Tonos: 'Tonos',
		Artistas: 'Artistas',
		Freemium: 'Freemium',
		Ilimitado: 'Ilimitado',
		Ilimitado5Tonos: 'Ilimitado 5 tonos',
		UsuarioIlimitado: 'Usuário ilimitado',
		ImagemDoGooglePlay: 'Imagem do Google Play',
		UsuarioMas: 'Usuário Más',
		ClicarForaDaModal: 'Clicar fora da Modal',
		Categoria: 'Categoria',
		Banner: 'Banner',
		Cards: 'Cards',
		Perfil: 'Perfil',
		AcessoPerfil: 'Acesso Perfil',
		MisTonos: 'Mis Tonos',
		MeusContatos: 'Meus Contatos',
		SetaVoltar: 'Seta Voltar',
		AcessoACategorias: 'Acesso a Categorias',
		CliqueAbaTonos: 'Clique Aba Tonos',
		CliqueAbaArtistas: 'Clique Aba Artistas',
		CliqueNaPergunta: 'Clique na Pergunta',
		Search: 'Search',
		EncerrarSessao: 'Encerrar Sessão',
		EditarNome: 'Editar Nome',
		EditarTono: 'Editar Tono',
		SelecionarContato: 'Selecionar Contato',
		Seguir: 'Seguir',
		Msisdn: 'Msisdn',
		Confirmar: 'Confirmar',
		IdentificacaoSMS: 'Identificação SMS',
		Cancelar: 'Cancelar',
		Downgrade: 'Downgrade',
		ReenviarCodigo: 'Reenviar Código',
		FecharModal: 'Fechar Modal',
		Aceitar: 'Aceitar',
		Assinar: 'Assinar',
		Encontrado: 'Encontrado',
		NaoEncontrado: 'Não encontrado',
		AbrirGooglePlayStore: 'Abrir Google Play Store',
		ContestoneMas: 'Contestone Más',
	},
};
