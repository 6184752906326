import React from 'react';
import { NavLink } from 'react-router-dom'
import appConfig from '../../application/config';

import logotypeContestoneText from '../../assets/svg/logotype-contestone-text.svg';
import logotypeTelcel from '../../assets/svg/logotype-telcel.svg';
import logotypeimusica from '../../assets/svg/logotype-imusica.svg';

const Footer = () => {
	const webRoutes = appConfig.web.routes;
	return (
		<footer>
	
			<div className="column">
				<div className="line">
					<figure className="logotype contestone">
						<img src={logotypeContestoneText} alt="Contestone" />
					</figure>
					<div className="logotype telcel">
						<img src={logotypeTelcel} alt="Telcel" />
					</div>
				</div>
				<div className="line">
					<span className="rules">
						<NavLink replace className={'item'} to={{ pathname: webRoutes.help, state: { modal: true } }}>Ayuda</NavLink>
						| <NavLink replace className={'item'} to={{ pathname: webRoutes.terms, state: { modal: true } }}>Términos y condiciones</NavLink>
						| <NavLink replace className={'item'} to={{ pathname: webRoutes.deleteData }}>Eliminar datos personales</NavLink>
					</span>
				</div>
			</div>
	
			<div className="column">
				<div className="line">
					<span className="rights">©2019 Contestone - Todos los derechos reservados</span>
				</div>
				<div className="line">
					<span className="powered">powered by</span>
					<figure className="logotype imusica">
						<img src={logotypeimusica} alt="iMusica" />
					</figure>
				</div>
			</div>
	
		</footer>
	)
}

export default Footer


