import React, { useMemo } from 'react';
import CardTone from './card-tone';
import Utils from '../../utils/utils';
import { formatDate } from '../../utils/date';
import Router from '../../utils/router';
import appConfig from '../../application/config';
import EditorialApi from '../../redux/api/editorial-api';
// import { Container } from './styles';

const webRoutes = appConfig.web.routes;

const TonesList = (props) => {
	let TonesList = props.List;
	let activePromo = props.activePromo;
	let noItems = props.noItems;
	let gaLabel = Utils.gaEvents().Label.Cards + (props.tabName ? ' - ' + props.tabName : '');

	const rules = useMemo(() => {
		try {
			return activePromo.editorials.find(e => e.title === 'RULES');
		} catch (e) {
			return undefined;
		}
	}, [activePromo]);

	if (props.AreaName) {
		gaLabel = props.AreaName + ' - ' + gaLabel;
	}

	let goToRules = () => {
		EditorialApi.setRules(rules);
		Router.goToModal({ pathname: webRoutes.rules, state: { modal: true } });
	};

	return (
		<section className="tonos-list"
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
		>
			{TonesList && TonesList.map((item, index) =>
				<CardTone Tone={item} key={item.id} Label={gaLabel + ' - ' + (index + 1)} />
			)}
			{noItems && <p className="no-items">{noItems}</p>}
			<div className="pipe"></div>

			{props.behavior === 'PROMO' && activePromo &&
				<footer>
					<div className="end-date">Hasta el {formatDate(activePromo.end_date)}</div>
					{rules &&
						<div className="button type-1" onClick={goToRules}>
							<span>revisa las bases</span>
						</div>
					}
				</footer>}

		</section>
	);
};

export default TonesList;


