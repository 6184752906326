import React, {useEffect, useLayoutEffect, useState, useRef} from 'react';
import {useSelector} from 'react-redux'
import appConfig from "../../application/config";
import Router from "../../utils/router";
import TooltipApi from "../../redux/api/tooltip-api";
import Utils from '../../utils/utils'

const TooltipTone = (props) => {
	const webRoutes = appConfig.web.routes

	const tooltipArea = useRef(null);
	const tooltip = useSelector(state => state.tooltipState.tone)

	let [posX, setPosX] = useState(false)
	let [posY, setPosY] = useState(false)
	let [active, setActive] = useState(false)

	const elementPosition = () => {
		const btnProps = tooltip.element.getBoundingClientRect();
		const elProps = tooltipArea.current.getBoundingClientRect();
		setPosY(btnProps.top - elProps.height);
		setPosX(btnProps.right - elProps.width + 15);
	}

	const handleClickOutside = (e) => {
		if (tooltipArea.current && !tooltipArea.current.contains(e.target))
			TooltipApi.toneReset()
	}

	const goTo = (location) => {
		TooltipApi.toneReset()
		Router.goToModal(location)
	}

	useEffect(() => {
		setActive(!(!tooltip.element || (!tooltip.isActive && !tooltip.message)))
	}, [tooltip])

	useLayoutEffect(() => {
		if (!tooltip.element) return
		elementPosition()
		if (tooltip.message)
			setTimeout(() => TooltipApi.tone({message: false, element: tooltip.element}), 3000)
		window.addEventListener('resize', elementPosition, true)
		document.addEventListener("click", handleClickOutside, false)
		document.addEventListener('scroll', TooltipApi.toneReset, true)
		return () => {
			window.removeEventListener('resize', elementPosition, true)
			document.removeEventListener("click", handleClickOutside, false)
			document.removeEventListener('scroll', TooltipApi.toneReset, true)
		}
	}, [tooltip])

	const openModalAndRegisterGa = (pathname, event, label) => {
		Utils.gaSend('', event, label)

		goTo({pathname, state: {modal: true, label: event}})
	}

	return (
		<div className={"tooltip-tone" + (active ? " fadeIn" : " fadeOut")} ref={tooltipArea} onScroll={elementPosition} style={{
			top: posY + "px",
			left: posX + "px"
		}}>

			<div className="labels">
				<label>Elegír contacto</label>
				<label>Para todos</label>
				<label>Editar tono</label>
			</div>

			<div className="baloon">
				<div className="container">
					<div className="button type-1" onClick={() => { openModalAndRegisterGa(webRoutes.googleplay, Utils.gaEvents().Label.SelecionarContato, tooltip.gaLabel) }}>
						<div className={"icon icon-select"}/>
					</div>
					<div className={"button type-1" + (tooltip.isActive ? " on" : "")} onClick={tooltip.deactivate}>
						<div className="icon icon-users"/>
					</div>
					<div className="button type-1" onClick={() => { openModalAndRegisterGa(webRoutes.googleplay, Utils.gaEvents().Label.EditarTono, tooltip.gaLabel) }}>
						<div className={"icon icon-edit"}/>
					</div>
					<p className={"message-over" + (tooltip.message ? " fadeIn" : " fadeOut")}>
						{tooltip.isActive ? "Tono activo para todos" : "Tono desactivado para todos"}
					</p>
					<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 156 65">
						<path d="M130,51.6H26A25.8,25.8,0,1,1,26,0H130a25.8,25.8,0,0,1,18.5,44L130,65Z" transform="translate(0 0)"/>
					</svg>
				</div>
			</div>

		</div>
	);
}

export default TooltipTone


