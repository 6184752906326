import store from '../store';
import * as types from '../actions';

class StoreApi {
	/**
	 * Set value that was clicked
	 */
	
  static setStore(state) {
		  store.dispatch({
				type: types.SET_STORE,
				state
			});
			return Promise.resolve(state);		
	}
}

export default StoreApi;



