// REACT
import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import appConfig from '../../application/config';
import logotype from '../../assets/svg/logotype-contestone.svg';

import TooltipoApi from '../../redux/api/tooltip-api'
import Utils from '../../utils/utils';

const Header = () => {
	const webRoutes = appConfig.web.routes;
	const pathname = useHistory().location.pathname;

	return (
		<React.Fragment>
			<header>
				<div className="content">
					<NavLink className="logotype" to={webRoutes.tones}>
						<img src={logotype} alt=""/>
					</NavLink>
					<nav>
						<ul>
							<li>
								<NavLink className={'button type-menu disabled'} to={webRoutes.perfil} onClick={()=>{Utils.gaSend('', Utils.gaEvents().Label.Perfil, Utils.gaEvents().Label.AcessoPerfil + ' - ' + Utils.gaEvents().Label.MisTonos)}}>
									<div className="icon icon-tonos"/> <span>Mis Tonos</span>
								</NavLink>
							</li>
							<li>
								<NavLink className={'button type-menu'} to={webRoutes.tones}>
									<div className="icon icon-tonos"/> <span>Tonos</span>
								</NavLink>
							</li>
							<li>
								<NavLink replace className={'button type-menu' + (pathname.includes(webRoutes.category)?' active':'')} to={{pathname: webRoutes.categories, state: {modal: true}}}>
									<div className="icon icon-category"/><span>Categorias</span>
								</NavLink>
							</li>
							<li>
								<NavLink className={'button type-menu'} to={webRoutes.perfil} onClick={()=>{Utils.gaSend('', Utils.gaEvents().Label.Perfil, Utils.gaEvents().Label.AcessoPerfil)}}>
									<div className="icon icon-perfil"/><span>Mi Perfil</span>
								</NavLink>
							</li>
							<li>
								<a href="true" className={'button type-menu'} to={webRoutes.search} onClick={(e)=>{e.preventDefault(); TooltipoApi.Search(true)}}>
									<div className="icon icon-search"/><span>Buscar</span>
								</a>
							</li>
						</ul>
					</nav>
				</div>
			</header>
		</React.Fragment>
	)
}

export default Header


