import React, {useState, useRef} from 'react'

import Tone from '../../utils/tone'
import appConfig from '../../application/config'
import TooltipApi from "../../redux/api/tooltip-api";
import Router from '../../utils/router'
import Utils from '../../utils/utils'

const Contestone = (props) => {
	const buttonArea = useRef(null);
	const webRoutes = appConfig.web.routes
	let [isLoading, setIsLoading] = useState(false)

	const handleClick = (e) => {
		let isActive = Tone.isActive(props.Tone.id);
		if (!isActive)
			activate();
		TooltipApi.tone({
			isActive,
			idTone: props.Tone.id,
			show: true,
			deactivate,
			element: buttonArea.current,
			gaLabel: props.Label
		});
	}

	const activate = () => {
		setIsLoading(true)
		gaRegister(Utils.gaEvents().Action.AtivarTono, props.Label)
		Tone.active(props.Tone).then(() => {
			setTooltipParams({isActive: true, message: true, idTone: props.Tone.id, gaLabel: props.label})
		}).catch((error)=>{
			if(error){
				Router.goToModal({pathname: webRoutes.errorTone, state: {modal: true}})
			}
		}).finally(() =>{
			setIsLoading(false)
		});
	}

	const deactivate = () => {
		setIsLoading(true)
		gaRegister(Utils.gaEvents().Action.DesativarTono, props.Label)
		Tone.remove(props.Tone).then(() => {
			setTooltipParams({isActive: false, message: true, idTone: props.Tone.id, gaLabel: props.label})
		}).catch((error)=>{
			if(error){
				Router.goTo({pathname: webRoutes.ErrorModal, state: {modal: true, errorMessage: error}})
			}
		}).finally(() => {
			setIsLoading(false)
		});
	}

	const setTooltipParams = (params) => {
		TooltipApi.tone({
			...params,
			element: buttonArea.current
		})
	}

	const gaRegister = (action, label) => {
		Utils.gaSend('', action, label)
	}

	return (
		<a className={"button type-3 contestone"} ref={buttonArea} onClick={handleClick}>
			{!!isLoading ?
				<div className={"icon icon-loading" + (props.loadingWhite ? "-white" : "")}/>
				:
				<div className={"icon icon-contestone-" + (Tone.isActive(props.Tone.id) ? "on" : "off")}/>
			}
			<span>Contestone</span>
		</a>
	)
}

export default Contestone