import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useLocation, NavLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FbMetrics } from '../../utils/fbMetrics';

// REDUX API
import CatalogApi from '../../redux/api/catalog-api';
import appConfig from '../../application/config';
import ColorApi from '../../redux/api/color-api';

import Carousel from '../shared/carousel';
import CardTone from '../shared/card-tone';
import TonesList from '../shared/tones-list';

import User from '../../utils/user';
import Utils from '../../utils/utils';
import Router from '../../utils/router';

const Tones = () => {
	const webRoutes = appConfig.web.routes;
	const userInfo = useSelector(store => store.userState.info);
	const catalog = useSelector(store => store.catalogState.catalog);
	const colorTab = useSelector(store => store.colorState.tab);
	const colorStripe = useSelector(store => store.colorState.stripe);
	const history = useHistory();
	const enableAutoScroll = useRef(true);
	const carouselRef = useRef(null);
	const activeHighlightsRef = useRef([]);
	// const colorFont = useSelector(store => store.colorState.font)
	const { Tab } = useParams();
	const [CurrentTab, setCurrentTab] = useState('');
	const [ListActiveTab, setListActiveTab] = useState(false);
	const [showFreemium, setShowFreemium] = useState(true);
	const [activePromo, setActivePromo] = useState(0);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const location = useLocation();

	const activeTones = useMemo(() => {
		if (ListActiveTab.behavior === 'PROMO' && ListActiveTab.children && ListActiveTab.children[activePromo]) {
			return ListActiveTab.children[activePromo].tones || [];
		}
		return ListActiveTab.tones || [];
	}, [activePromo, ListActiveTab]);

	useEffect(() => {
		const _setWindowWidth = () => {
			setWindowWidth(window.innerWidth);
			if (window.innerWidth < 768) {
				enableAutoScroll.current = false;
			} else {
				enableAutoScroll.current = true;
			}
		};

		window.addEventListener('resize', _setWindowWidth);

		const autoScroll = setInterval(() => {
			if (enableAutoScroll.current) {
				const currentSlide = carouselRef.current.getSlide();
				if (activeHighlightsRef.current && ((currentSlide + 1) < (activeHighlightsRef.current.length))) {
					carouselRef.current.goToSlide(currentSlide + 1);
				} else {
					carouselRef.current.goToSlide(0);
				}
			}
		}, 3000);

		if (window.innerWidth < 768) {
			enableAutoScroll.current = false;
		} else {
			enableAutoScroll.current = true;
		}

		return () => {
			clearInterval(autoScroll);
			window.removeEventListener('resize', _setWindowWidth);
		};
	}, []);

	const activeHighlights = useMemo(() => {
		if (ListActiveTab.behavior === 'PROMO') {
			let banners = [];
			ListActiveTab.children.forEach(child => {
				let responsiveBanner;
				responsiveBanner = child.banners.find(el => el.type === 'DESKTOP');
				if (windowWidth < 768) {
					responsiveBanner = child.banners.find(el => el.type === 'MOBILE');
				}
				if (responsiveBanner) {
					banners = [...banners, responsiveBanner];
				}
			});
			activeHighlightsRef.current = banners;
			return banners;
		}
		activeHighlightsRef.current = ListActiveTab.highlights;
		return ListActiveTab.highlights;
	}, [ListActiveTab, windowWidth]);

	const beforeSlickChange = useCallback((index) => {
		setActivePromo(index);
	}, []);

	const onSwipe = useCallback((direction) => {
		const currentSlide = carouselRef.current.getSlide();
		if (currentSlide === 0 && direction === 'right') {
			const indexOf = catalog.findIndex(el => el.id === ListActiveTab.id);
			let item = catalog[catalog.length - 1];
			if (indexOf - 1 >= 0) {
				item = catalog[indexOf - 1];
			}
			history.push(`${webRoutes.tones}/${item.alias}`);
		} else if (currentSlide === activeHighlightsRef.current.length - 1 && direction === 'left') {
			const indexOf = catalog.findIndex(el => el.id === ListActiveTab.id);
			let item;
			if (indexOf + 1 <= catalog.length - 1) {
				item = catalog[indexOf + 1];
			} else {
				item = catalog[0];
				if (item.behavior === 'FREEMIUM' && !showFreemium) {
					item = catalog[1];
				}
			}
			history.push(`${webRoutes.tones}/${item.alias}`);
		}
	}, [history, catalog, webRoutes, ListActiveTab, showFreemium]);

	const onPrevArrow = useCallback(() => {
		const currentSlide = carouselRef.current.getSlide();
		if (currentSlide === 0) {
			const indexOf = catalog.findIndex(el => el.id === ListActiveTab.id);
			let item = catalog[catalog.length - 1];
			if (indexOf - 1 >= 0) {
				item = catalog[indexOf - 1];
			}
			history.push(`${webRoutes.tones}/${item.alias}`);
		}
	}, [catalog, history, webRoutes, ListActiveTab]);

	const onNextArrow = useCallback(() => {
		const currentSlide = carouselRef.current.getSlide();
		if (currentSlide === activeHighlightsRef.current.length - 1) {
			const indexOf = catalog.findIndex(el => el.id === ListActiveTab.id);
			let item;
			if (indexOf + 1 <= catalog.length - 1) {
				item = catalog[indexOf + 1];
			} else {
				item = catalog[0];
				if (item.behavior === 'FREEMIUM' && !showFreemium) {
					item = catalog[1];
				}
			}
			history.push(`${webRoutes.tones}/${item.alias}`);
		}
	}, [catalog, history, webRoutes.tones, showFreemium, ListActiveTab]);


	useEffect(() => {
		setShowFreemium(User.isFreemium() || !User.isSubscribed());
	}, [userInfo]);

	// Welcome new user
	useEffect(() => {
		if (location.state) {
			if (location.state.openModal) {
				openModal(location.state.openModal.type);
			}
		}

	}, [location]);

	const openModal = (type) => {
		Router.goToModal({ pathname: type, state: { modal: true } });
	};

	const getListActiveTab = useCallback(() => {
		let tabFreemium = catalog.find(item => item.behavior === 'FREEMIUM');

		const tabAlias = Tab ? Tab : tabFreemium.alias;
		let tabActive = catalog.find(item => item.alias === tabAlias);

		if (!tabActive || (tabActive.behavior === 'FREEMIUM' && !showFreemium))
			tabActive = catalog[0];

		if (tabActive.name.toLowerCase().includes('promo') || tabActive.behavior === 'PROMO') {
			FbMetrics.sendPageView();
		}

		setListActiveTab(tabActive);
		setCurrentTab(tabActive.alias);

		ColorApi.setColorTab(tabActive.color);
		ColorApi.setColorStripe(tabActive.color);
		ColorApi.setColorFont(tabActive.color);

	}, [Tab, catalog, showFreemium]);

	useEffect(() => {
		if (!catalog.length)
			CatalogApi.getCatalog(true, true);
		else
			getListActiveTab();
	}, [catalog, Tab, showFreemium, getListActiveTab]);

	useEffect(() => {
		carouselRef.current.goToSlide(0, false);
	}, [ListActiveTab]);

	return (
		<>
			<section className="tonos">
				<section className="tabs">
					<div className="content" style={{ backgroundColor: colorTab }}>
						<ul>
							{catalog && catalog.map((item, key) =>
								((item.behavior === 'FREEMIUM' && showFreemium) || item.behavior !== 'FREEMIUM') &&
								<li key={item.alias} style={(CurrentTab === item.alias) ? { color: colorTab } : {}}>
									<NavLink className={(CurrentTab === item.alias) ? 'active' : ''}
										to={`${webRoutes.tones}/${item.alias}`}>
										{item.name}
									</NavLink>
								</li>
							)}
						</ul>
					</div>
				</section>

				<section className="tonos-carousel"
					onMouseEnter={() => {
						enableAutoScroll.current = false;
					}}
					onMouseLeave={() => {
						if (window.innerWidth > 768) {
							enableAutoScroll.current = true;
						}
					}}>
					<div className="stripe" style={{ backgroundColor: colorStripe }} />
					<Carousel
						ref={carouselRef}
						onSwipe={onSwipe}
						nextArrowClick={onNextArrow}
						prevArrowClick={onPrevArrow}
						behavior={ListActiveTab.behavior}
						afterChange={ListActiveTab.behavior === 'PROMO' ? beforeSlickChange : undefined}
						Highlights={activeHighlights}
						TabName={ListActiveTab.name}
					/>
				</section>
				<div className="tonos-list-overflow">
					<TonesList
						activePromo={ListActiveTab.behavior === 'PROMO' ? ListActiveTab.children[activePromo] : undefined}
						List={activeTones}
						tabName={ListActiveTab.name}
						behavior={ListActiveTab.behavior}
						onMouseEnter={() => {
							enableAutoScroll.current = false;
						}}
						onMouseLeave={() => {
							if (window.innerWidth > 768) {
								enableAutoScroll.current = true;
							}
						}}
					/>
				</div>
			</section>
		</>
	);
};

export default Tones;


