import React, { useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import Slider from 'react-slick';
import DefaultHighlight from './defaultHighlight';
import PromoHighlight from './promoHighlight';
import Cover from '../../../assets/svg/album-cover.svg';
import Utils from '../../../utils/utils';

const Carousel = React.forwardRef((props, ref) => {
	const slide = useRef(0);
	const slidesCountRef = useRef(0);
	const slider = useRef(null);

	const settings = {
		dots: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		afterChange: (current) => {
			if (props.afterChange) {
				props.afterChange(current);
			}
			if (slidesCountRef.current <= current) {
				setTimeout(() => {
					slider.current.slickGoTo(0, true);
				}, 50);
			}
			slide.current = current;
		},
		beforeChange: (current, next) => {
			if (props.beforeChange) {
				props.beforeChange(current, next);
			}
		},
		onSwipe: (arg) => {
			if (props.onSwipe) {
				props.onSwipe(arg);
			}
		},
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					nextArrow: <NextArrow />,
					prevArrow: <PrevArrow />,
					accessibility: false,
					dots: true,
					arrows: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					onSwipe: (arg) => {
						if (props.onSwipe) {
							props.onSwipe(arg);
						}
					},
					afterChange: (current) => {
						if (props.afterChange) {
							props.afterChange(current);
						}
						if (slidesCountRef.current <= current) {
							setTimeout(() => {
								slider.current.slickGoTo(0, true);
							}, 50);
						}
						slide.current = current;
					},
					beforeChange: (current, next) => {
						if (props.beforeChange) {
							props.beforeChange(current, next);
						}
					},
				}
			}
		]
	};

	function NextArrow(properties) {
		const { className, style, onClick } = properties;
		return (
			<div
				className={className}
				style={{ ...style, display: 'block', cursor: 'pointer' }}
				onClick={() => {
					if (props.nextArrowClick) {
						props.nextArrowClick();
					}

					if (onClick) {
						onClick();
					}
				}}
			/>
		);
	}

	function PrevArrow(properties) {
		const { className, style, onClick } = properties;
		return (
			<div
				className={className}
				style={{ ...style, display: 'block', cursor: 'pointer' }}
				onClick={() => {
					if (props.prevArrowClick) {
						props.prevArrowClick();
					}
					if (onClick) {
						onClick();
					}
				}}
			/>
		);
	}

	const getSlide = useCallback(() => {
		return slide.current;
	}, []);

	const goToSlide = useCallback((index, transition = true) => {
		slider.current.slickGoTo(index, !transition);
	}, []);

	useEffect(() => {
		slidesCountRef.current = props.Highlights ? props.Highlights.length : 0;
	}, [props.Highlights]);

	useImperativeHandle(ref, () => ({
		getSlide,
		goToSlide
	}));

	const getBannerImage = (item) => {
		if (item.albumImageUrl) {
			return item.albumImageUrl;
		} else if (item.albumImages && item.albumImages.HD && !item.albumImages.HD.includes('_SEM FOTO')) {
			return item.albumImages.HD;
		} else {
			return Cover;
		}
	};

	let gaLabel = Utils.gaEvents().Label.Banner + ' - ' + props.TabName;
	if (props.AreaName) {
		gaLabel = props.AreaName + ' - ' + gaLabel;
	}

	return (
		<div>
			<Slider {...settings} infinite={false} className={props.behavior === 'PROMO' ? 'slick-promo' : ''} ref={slider}>
				{
					props.Highlights && props.Highlights.map((item, index) => {
						if (props.behavior === 'PROMO') {
							return <PromoHighlight key={item.id || index} item={item} index={index} gaLabel={gaLabel} getBannerImage={getBannerImage} />;
						}
						return <DefaultHighlight key={item.id || index} item={item} index={index} gaLabel={gaLabel} getBannerImage={getBannerImage} />;
					})
				}
			</Slider>
		</div>
	);

});

export default Carousel;
