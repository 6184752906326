import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import Modal from './modal'
import Router from "../../utils/router";
import ErrorMessage from '../shared/errorMessage'

const ErrorTone = () => {
	const location = useLocation()
	let [errorType, setErrorType] = useState(false)

	useEffect(() => {
		if(location.state && location.state.errorMessage){
			setErrorType(location.state.errorMessage)
			setTimeout(()=>{
				Router.goToRoot()
			}, 4500)
		}
	},[location])

	return (
		<Modal
			bg={''}
			type={'3'}
			hideClose={true}
			content={
				<>
					<ErrorMessage type={errorType} resetErrorType={setErrorType} />
				</>
			}
		/>
	)
}

export default ErrorTone


