import Type1 from './type-1';
import Type2 from './type-2';
import Type3 from './type-3';
import Type4 from './type-4';
import Type5 from './type-5';
import Type6 from './type-6';
import Type7 from './type-7';

export default {
	Type1,
	Type2,
	Type3,
	Type4,
	Type5,
	Type6,
	Type7,
};
