import React, {useState, useEffect} from 'react'
import Router from '../../utils/router'
import { useHistory } from 'react-router-dom';

import Modal from './modal'
import appConfig from '../../application/config'

//Api
import UserApi from '../../redux/api/user-api'

//Components
import SendSms from './send-sms.jsx';
import PinCode from './pincode.jsx';

//Utils
import User from '../../utils/user'
import Utils from '../../utils/utils'

const Auth = (props) => {
	const webRoutes = appConfig.web.routes
	
	let history = useHistory()

	const ToneFreemium = history.location.state ? history.location.state.isFreemium : false
	const subscribeContestoneMas = history.location.state ? history.location.state.subscribeContestoneMas : false
	const isDeletePersonalData = history.location.state ? history.location.state.deletePersonalData : false

	let [message, setMessage] = useState({type: '', text: ''})
	let [msisdn, setMsisdn] = useState(null)
	let [smsSent, setSmsSent] = useState(false)


	useEffect(() => {
		let userLogged = User.isLogged()
		let isHe = User.isHe()
		
		if(isHe && !userLogged){
			Router.goToModal({pathname: webRoutes.upsellRbt, state: {modal: true}})
		}
	})

	const subscribeMas = (msisdn, pincode)=>{
		if(!User.isContestoneMas()){
			User.subscribe(appConfig.planTypes.CONTESTONE_MAS, msisdn, pincode).then((res)=>{
				Router.goTo({pathname: webRoutes.tones, state: {openModal: {type: webRoutes.contestoneMasOk } }})
			}).catch((error)=>{
				dispatchError({type: 'error', text: error})
			})
		} else {
			Router.goTo({pathname: webRoutes.tones, state: {openModal: {type: webRoutes.welcomeAgain } }})
		}
	}

	const dispatchError = (message) => {
		setMessage(message)
		setTimeout(() => {
			setMessage({type: '', text: ''})
		}, 50000)
	}

	const sendSms = (msisdn, resend, label) => {
		setMsisdn(msisdn);
		gaRegister(label)
		
		return UserApi.sendSmsCode(msisdn).then(res => {
			setSmsSent(true);
			
			if(resend){
				dispatchError({type: 'success', text: 'MESSAGE_RESEND_PINCODE'})
			}
		}).catch(err => {
			dispatchError({type: 'error', text: 'ERROR'})
		})
	}

	const validate = (pincode, label) => {
		gaRegister(label)

		return UserApi.login(msisdn, pincode).then(()=>{
			if(subscribeContestoneMas){
				subscribeMas(msisdn, pincode)
			} else if (isDeletePersonalData){
				Router.goToModal({pathname: webRoutes.deleteDataConfirmation, state: {modal: true}});
			} else {
				User.redirect()
			}
		}).catch(error => {
			if(error === "ERROR_SUBSCRIPTION_NOT_ACTIVE"){
				if(ToneFreemium){
					Router.goToModal({pathname: webRoutes.welcomeFreemium, state: {modal:true, msisdn, pincode}})
				} else if(subscribeContestoneMas){
					subscribeMas(msisdn, pincode)
				} else {
					Router.goToModal({pathname: webRoutes.upsellRbt, state: {modal:true, msisdn, pincode}})
				}
			} else {
				dispatchError({type: 'error', text: error})
			}
		})
	}

	const gaRegister = (label) => {
		if(!label) return
		Utils.gaSend('', Utils.gaEvents().Action.Login, label)
	}

	return (
		<Modal
			bg={''}
			type={'3'}
			AreaName={Utils.gaEvents().Action.Login}
			content={(!smsSent) ?
				<SendSms sendSms={sendSms} message={message}/>
				:
				<PinCode sendSms={sendSms} validate={validate} msisdn={msisdn} message={message}/>
			}
		/>
	)
}

export default Auth


