export const SET_STORE = 'SET_STORE';

export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';

// LANDING
export const LANDING_DATA = 'LANDING_DATA';
export const LANDING_ERROR = 'LANDING_ERROR';
export const LANDING_SCREEN = 'LANDING_SCREEN';
export const LANDING_MSISDN = 'LANDING_MSISDN';
export const LANDING_USER_PLAN = 'LANDING_USER_PLAN';

// USER
export const USER_DATA = 'USER_DATA';
export const USER_NAME = 'USER_NAME';
export const USER_RESET = 'USER_RESET';
export const USER_ADD_TONE = 'USER_ADD_TONE';
export const USER_REMOVE_TONE = 'USER_REMOVE_TONE';
export const USER_PLAN_LIST = 'USER_PLAN_LIST';
export const USER_HE = 'USER_HE';

// CATALOG
export const SET_CATALOG = 'SET_CATALOG';
export const SET_CATEGORY_LIST = 'SET_CATEGORY_LIST';

// EDITORIAL
export const SET_EDITORIAL_HELP = 'SET_EDITORIAL_HELP';
export const SET_EDITORIAL_TERMS = 'SET_EDITORIAL_TERMS';
export const SET_EDITORIAL_RULES = 'SET_EDITORIAL_RULES';

// Preview
export const IS_PLAYING = 'IS_PLAYING';
export const PLAY_PREVIEW = 'PLAY_PREVIEW';
export const STOP_PREVIEW = 'STOP_PREVIEW';
export const LOADING_PREVIEW = 'LOADING_PREVIEW';

// Colors
export const COLOR_TAB = 'COLOR_TAB';
export const COLOR_STRIPE = 'COLOR_STRIPE';
export const COLOR_FONT = 'COLOR_FONT';
export const COLOR_STRIPE_CATEGORY = 'COLOR_STRIPE_CATEGORY';

// TOOLTIPS
export const TOOLTIP_TONE = 'TOOLTIP_TONE';
export const TOOLTIP_SEARCH = 'TOOLTIP_SEARCH';
export const TOOLTIP_TONE_RESET = 'TOOLTIP_TONE_RESET';

// REDIRECT
export const REDIRECT_RESET = 'REDIRECT_RESET';
export const REDIRECT_AUTH_CALLBACK = 'REDIRECT_AUTH_CALLBACK';
