export const formatDate = (date) => {
	const parts = date.split('-');
	return `${fillWithZeros(parts[ 2 ].split(' ')[0], 2)}/${fillWithZeros(parts[ 1 ], 2)}/${parts[ 0 ]}`;
};

const fillWithZeros = (word, size = 2) => {
	let formatted = String(word);
	while (formatted.length < size) {
		formatted = `0${formatted}`;
	}
	return formatted;
};
