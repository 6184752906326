import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import EditorialApi from '../../redux/api/editorial-api';
import Modal from './modal'
import Collapse from '../shared/collapse.jsx';
import Utils from '../../utils/utils'

const Help = () => {
	let help = useSelector(store => store.editorialState.help)
	let [content, setContent] = useState(null)

	useEffect(() => {
		if (!help)
			EditorialApi.getHelp().catch((err) => {
				setContent(err.error);
			});
		else
			setContent(help);
	}, [help]);

	useEffect(() => {
		Utils.gaSend('', Utils.gaEvents().Action.Ajuda)
	},[])

	return (
		<Modal
			type={'5'}
			title={'Preguntas frecuentes'}
			content={
				<>
					<section className="help">
						<div className='container'>
							{content && content.map((item, key) =>
								<Collapse caption={item.question} key={key} item={(key+1)}	AreaName={Utils.gaEvents().Action.Ajuda}>
									<p>{item.answer}</p>
								</Collapse>
							)}
						</div>
					</section>
				</>
			}
		/>
	);
}

export default Help