

//
// Brasil
// ----------------------------------------------------------------------------

module.exports = {
  name: 'brasil',
  ct: 'br',
  language: 'pt',
  page: {
    title: 'Claro Som de Chamada',
  }
}
/*
brasil = {
  store: 'brasil',
  alias: 'br',
  ct: 'BR',
  page: {
    title: 'Claro Som de Chamada',
    style: 'brasil.less'
  },
  language: {
    code: 'pt',
    messages: 'message_pt',
  },
  currency: {
    code: 'BRL',
    symbol: 'R$'
  },
  subscription: {
    doubleOptIn: true,
    freemium: false,
    limitedTonesFreemium: 5
  },
  offer: {
    offerActiveToneInPlayer: false,
  },
  msisdn: {
    countryCode: '55',
    minLength: '11',
    maxLength: '11'
  },
  validateCode: {
    size: 4,
    termsConfirm: true,
    svaClaro: true
  },
  externalAppUrl: {
    linkBannerLoggedIn: true,
    playStore: "https://play.google.com/store/apps/details?id=br.com.rbt.android"
  },
  footer: {
    logos:[
      {
        id: 1,
        name: 'Claro Som de Chamada',
        img: 'logo-clarosomdechamada.png',
      }
    ]
  },
  socialConfig: {
    fb_app_id: '668554923524649',
    fb_pixel_id: '1816764975100935',
		share_text: 'Ative agora como Som de Chamada:'
  },
  menuHome: [
    {
      id: 1,
      alias: "home"
    },
    {
      id: 2,
      alias: "genre"
    },
    {
      id: 3,
      alias: "my-tones"
    },
    {
      id: 4,
      alias: "help"
    }
  ],
  helpList: [
    {
      id: 1,
      alias: "faq"
    },
    {
      id: 2,
      alias: "terms"
    },
    {
      id: 3,
      alias: "plans"
    },
    {
      id: 4,
      alias: "privacy"
    }
  ]
};


module.exports = brasil;*/