// React

import React, {useEffect, useState} from 'react'
import StoreApi from '../../redux/api/store-api'
import storeConfig from '../../application/stores'

const ProviderStore = (props) => {
	const [state, setState] = useState('')
	let PropsChildren = ''

	useEffect(() => {
		const getStore = new storeConfig(window.location.origin)
		getStore.then((res)=>{
			StoreApi.setStore(res)
			setState(res)
		});
	}, [])


	if(state){
		PropsChildren = props.children
	}
	
	return (
		<>
			{PropsChildren}
		</>
	)
}
export default ProviderStore
