import React from 'react'
import appConfig from './application/config'
import { Switch, Route, useLocation } from 'react-router-dom'

import ContestoneMas from './components/modal/contestone-mas'
import ContestoneMasOk from './components/modal/contestone-mas-ok'
import WelcomeFreemium from './components/modal/welcome-freemium'
import WelcomeAgain from './components/modal/welcome-again'
import UpsellRbt from './components/modal/upsell-rbt'
import UpsellRbtOk from './components/modal/upsell-rbt-ok'
import Gratis5Tones from './components/modal/gratis-5tones'
import Auth from './components/modal/auth'
import Googleplay from './components/modal/googleplay'
import PerfilCancelUnlimited from './components/modal/perfil-cancel-unlimited'
import PerfilCancelUnlimitedOk from './components/modal/perfil-cancel-unlimited-ok'
import PerfilDowngrade from './components/modal/perfil-downgrade'
import PerfilCancelMas from './components/modal/perfil-cancel-mas'
import PerfilCancelMasOk from './components/modal/perfil-cancel-mas-ok'
import PerfilExit from './components/modal/perfil-exit'
import ErrorTone from './components/modal/error-tone'
import ErrorModal from './components/modal/error-modal'
import DeleteDataConfirmation from './components/modal/deleteDataConfirmation'
import DeleteDataConfirmationSuccess from './components/modal/deleteDataConfirmationSuccess'
import DeleteDataConfirmationError from './components/modal/deleteDataConfirmationError'

import Categories from './components/modal/categories'

import Terms from './components/modal/terms'
import Help from './components/modal/help'
import Rules from './components/modal/rules'

function ModalRouter() {
	const webRoutes = appConfig.web.routes;
	const location = useLocation()

	let path = location.pathname.split('/');
	let isModal = appConfig.web.modalsRouter.indexOf(path[1]) > -1;
	let showModal = isModal ? ' fadeIn' : ' fadeOut'

	return (
		<div className={"modal " + (showModal)} >
			<Switch>
				<Route exact path={webRoutes.categories} component={Categories} />
				<Route path={webRoutes.auth} component={Auth} />
				<Route path={webRoutes.googleplay} component={Googleplay} />

				<Route path={webRoutes.contestoneMas} component={ContestoneMas} />
				<Route path={webRoutes.contestoneMasOk} component={ContestoneMasOk} />

				<Route path={webRoutes.welcomeAgain} component={WelcomeAgain} />
				<Route path={webRoutes.welcomeFreemium} component={WelcomeFreemium} />
				<Route path={webRoutes.upsellRbt} component={UpsellRbt} />
				<Route path={webRoutes.upsellRbtOk} component={UpsellRbtOk} />
				<Route path={webRoutes.gratis5Tones} component={Gratis5Tones} />

				<Route path={webRoutes.perfilCancelUnlimited} component={PerfilCancelUnlimited} />
				<Route path={webRoutes.perfilCancelUnlimitedOk} component={PerfilCancelUnlimitedOk} />
				<Route path={webRoutes.perfilDowngrade} component={PerfilDowngrade} />
				<Route path={webRoutes.perfilCancelMas} component={PerfilCancelMas} />
				<Route path={webRoutes.perfilCancelMasOk} component={PerfilCancelMasOk} />
				<Route path={webRoutes.errorTone} component={ErrorTone} />
				<Route path={webRoutes.errorModal} component={ErrorModal} />
				<Route path={webRoutes.perfilExit} component={PerfilExit} />

				<Route path={webRoutes.terms} component={Terms} />
				<Route path={webRoutes.help} component={Help} />
				<Route path={webRoutes.rules} component={Rules} />

				<Route path={webRoutes.deleteDataConfirmation} component={DeleteDataConfirmation} />
				<Route path={webRoutes.deleteDataConfirmationSuccess} component={DeleteDataConfirmationSuccess} />
				<Route path={webRoutes.deleteDataConfirmationError} component={DeleteDataConfirmationError} />
			</Switch>
		</div>
	)
}

export default ModalRouter


