import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import usePrevious from '../providers/usePrevious';

// REDUX
import CatalogApi from '../../redux/api/catalog-api';

// COMPOENENTS
import Carousel from '../shared/carousel';
import TonesList from '../shared/tones-list';

import appConfig from '../../application/config';
import dbModel from '../../models/DB.models';
import Utils from '../../utils/utils';
import Router from '../../utils/router';

const Category = () => {
	const { Alias } = useParams();
	const [Tones, setTones] = useState([]);
	const [Highlights, setHighlights] = useState([]);
	const dispatch = useDispatch();
	let categoryList = useSelector(store => store.catalogState.categoryList);
	let colorStripe = useSelector(state => state.colorState.stripeCategory);
	let [CurrentAlias, setCurrentAlias] = useState('');
	const prevAlias = usePrevious(CurrentAlias);

	useEffect(() => {
		if (categoryList.length) return;
		CatalogApi.getCategoryList();
	}, []);

	useEffect(() => {
		if (!categoryList || (Alias === prevAlias)) return;
		getCategory(Alias);
	}, [categoryList, Alias]);

	const getID = (_Alias) => {
		let idCategory = '';
		for (let cl of categoryList)
			if (cl.alias === _Alias)
				idCategory = cl.id;
		return idCategory;
	};

	const getCategory = () => {
		const getCategory = new dbModel(false, false);
		const idCategory = getID(Alias);
		getCategory({
			method: 'get',
			url: appConfig.apiConnection.paths.Category + '/' + idCategory + '/start/1/end/50'
		}).then(res => {
			setHighlights(res.data.highlights);
			setTones(res.data.tones);
			dispatch({ type: 'COLOR_STRIPE_CATEGORY', state: res.data.color });
			Utils.gaSend('', Utils.gaEvents().Action.Categoria, Alias);
			setCurrentAlias(Alias);
		}).catch((error) => {
			console.error(error);
		});
	};

	const Back = () => {
		Router.goBack();
		Utils.gaSend('', Utils.gaEvents().Action.Categoria, Utils.gaEvents().Label.SetaVoltar + ' - ' + Alias);
	};

	return (
		<>
			<section className="category">

				<section className="header" style={{ backgroundColor: colorStripe }}>
					<div className="container">
						<div className="button type-link" onClick={() => Back()}>
							<div className="icon icon-back" />
						</div>
						<h1 style={{ color: colorStripe }}>{Alias}</h1>
					</div>
				</section>

				<section className="tonos-carousel">
					<div className="stripe" style={{ backgroundColor: colorStripe }} />
					<Carousel Highlights={Highlights} AreaName={Utils.gaEvents().Label.Categoria} TabName={Alias} control={true} />
				</section>

				<TonesList List={Tones} AreaName={Utils.gaEvents().Label.Categoria} TabName={Alias} />

			</section>
		</>
	);
};

export default Category;
