import Modal from './modal'
import React, {useEffect} from 'react'
import User from '../../utils/user'

const PerfilCancelMas = () => {
	useEffect(() => {
		setTimeout(User.logout, 3000)
	},[])
	return (
		<Modal
			bg={''}
			type={'3'}
			hideClose={true}
			content={
				<>
					<p>Los tonos para tu celular y para quien te llama han sido cancelados</p>
				</>
			}
		/>
	)
}

export default PerfilCancelMas


