import { COLOR_TAB, COLOR_STRIPE, COLOR_STRIPE_CATEGORY } from '../actions'

const initialState = {
  tab: '',
  stripe: '',
  stripeCategory: ''
}

export const colorReducer = (state = initialState, action) => {
  switch (action.type) {
    case COLOR_TAB:
      return {
        ...state,
        tab: action.state
      };
    case COLOR_STRIPE:
        return {
          ...state,
          stripe: action.state
        };
    case COLOR_STRIPE_CATEGORY:
        return {
          ...state,
          stripeCategory: action.state
        };
    default:
      return state;
  }
};