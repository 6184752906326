import React, {useEffect} from 'react'
import { useLocation } from 'react-router-dom'
import Modal from './modal'
import Utils from '../../utils/utils'
import ButtonGooglePlay from '../buttons/button-googleplay'

const GooglePlay = () => {
	const location = useLocation()

	useEffect(() => {
		if(location.state && location.state.label){
			Utils.gaSend('', Utils.gaEvents().Action.GooglePlay, location.state.label)
		}
	}, []);

	return (
		<Modal
			bg={''}
			type={'3'}
			AreaName={Utils.gaEvents().Action.GooglePlay}
			content={
				<>
					<figure className='logotype'></figure>
					<p>Esta funcionalidad sólo está disponible en nuestra APP Android.</p>
					<p>Descárgala ahora para elegir los tonos que escuchan tus contactos y disfruta lo mejor de Contestone.</p>
					<ButtonGooglePlay/>
				</>
			}
		/>
	)
}

export default GooglePlay


