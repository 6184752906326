import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import Modal from './modal'
import User from "../../utils/user";
import Utils from "../../utils/utils";
import appConfig from "../../application/config";

import ErrorMessage from '../shared/errorMessage'

const WelcomeFreemium = () => {
	const location = useLocation()
	let [errorType, setErrorType] = useState(false)

	const msisdn = location.state.msisdn
	const pincode = location.state.pincode

	const subscribe = () => {
		gaRegister(Utils.gaEvents().Action.UpSelling, Utils.gaEvents().Label.Freemium+' - '+Utils.gaEvents().Label.Aceitar)

		User.subscribe(appConfig.planTypes.FREEMIUM, msisdn, pincode).then(() =>{
			User.redirect()
		}).catch((err)=>{
			setErrorType(err)
		})
	}

	const gaRegister = (action, label) => {
		Utils.gaSend('', action, label)
	}

	return (
		<Modal
			bg={'bg-graph-1'}
			type={'6'}
			AreaName={Utils.gaEvents().Action.UpSelling}
			Label={Utils.gaEvents().Label.Freemium}
			content={
				<>
					<figure className='logotype'/>
					<p className='font-weight-bold'>¡Bienvenido a Contestone Gratuito!</p>
					<p>Puedes activar hasta 5 tonos de esta sección</p>
					<ErrorMessage type={errorType} resetErrorType={setErrorType} />
					<div className="buttons">
						<div className="button type-1">
							<span onClick={subscribe}>
								aceptar
							</span>
						</div>
					</div>
				</>
			}
		/>
	)
}

export default WelcomeFreemium
