import React, { useEffect, useState } from 'react';
import dbModel from '../../models/DB.models';
import appConfig from '../../application/config';
import TonesList from '../shared/tones-list';
import ArtistList from '../shared/artist-list';
import Utils from '../../utils/utils';

const SearchTerms = (props) => {
	let [Tones, setTones] = useState([]);
	let [Artists, setArtists] = useState([]);
	let [View, setView] = useState('TONOS');
	let [Loading, setLoading] = useState(false);

	useEffect(() => {
		ListSingersAndTones(props.Terms);
	}, [props.Terms]);

	const ListSingersAndTones = (Terms) => {
		setLoading(true);
		let getList = new dbModel(false, false);
		getList({
			method: 'post',
			url: appConfig.apiConnection.paths.Tone.Single,
			data: {
				endRecordNum: 50,
				startRecordNum: 0,
				term: Terms
			}
		}).then(res => {
			setTones(res.data.tones);
			setArtists(res.data.singers);
			setLoading(false);
			RegisterEvents(Terms, res.data.tones, res.data.singers);
		}).catch(error => {
			console.error(error);
			setLoading(false);
		});
	};

	const RegisterEvents = (Terms, tones, singers) => {
		let Encontrado = (tones.length || singers.length ? Utils.gaEvents().Label.Encontrado : Utils.gaEvents().Label.NaoEncontrado);
		Utils.gaSend('', Utils.gaEvents().Action.Search, (Terms + ' - ' + Encontrado));
	};

	const setViewer = (View) => {
		setView(View);
		let Label = (View === 'TONOS' ? Utils.gaEvents().Label.CliqueAbaTonos : Utils.gaEvents().Label.CliqueAbaArtistas);
		Utils.gaSend('', Utils.gaEvents().Label.Search, Label);
	};

	return (
		<div className='result'>
			<div className="container">

				<header>
					<div className="switch">
						<div className={'button type-switch ' + (View === 'TONOS' ? 'active' : '')} onClick={() => setViewer('TONOS')}>
							<span>{'TONOS'}</span>
						</div>
						<div className={'button type-switch ' + (View === 'ARTISTS' ? 'active' : '')} onClick={() => setViewer('ARTISTS')}>
							<span>{'ARTISTAS'}</span>
						</div>
					</div>
				</header>

				{(View === 'TONOS') ? (
					<>
						<TonesList List={Tones} AreaName={Utils.gaEvents().Label.Search} TabName={Utils.gaEvents().Label.Tonos} />
						{(!Tones.length && !Loading) && <p>Sem Conteúdo</p>}
					</>
				) : (
						<ArtistList List={Artists} />
					)}

			</div>
		</div>
	);
};

export default SearchTerms;


