import React, { useState } from 'react';

import logotype from '../../assets/svg/logotype-contestone.svg';
import UserApi from '../../redux/api/user-api';
import { useSelector } from 'react-redux';
import LandingApi from '../../redux/api/landing-api';

const LandingMsisdn = () => {
	let [ msisdn, setMsisdn ] = useState(null);

	const store = useSelector(state => state.storeState.config);
	const msisdnLength = store.msisdn.maxLength;

	const handleChange = (e) => {
		let value = e.target.value.replace(/[^\d]+/, '');
		setMsisdn(value);
		e.target.value = value;
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter')
			sendSms();
	};

	const sendSms = () => {
		return UserApi.sendSmsCode(msisdn).then(() => {
			LandingApi.setMsisdn(msisdn);
			LandingApi.beginFlux(msisdn);
		});
	};

	return (
		<section className="wifi-msisdn">
			<figure className="logotype">
				<img src={logotype} alt="" />
			</figure>
			<h1>¡Bienvenido <br />a Contestone!</h1>
			<h2>Ingresa tu número Telcel<br />para que podamos identificarte:</h2>

			<input type="number" placeholder="Ingresa tu número Telcel..."
				onChange={handleChange} onKeyPress={handleKeyPress} maxLength={msisdnLength} />

			<div className="button type-4" onClick={sendSms}>
				<span>seguir</span>
			</div>

		</section>
	);
};

export default LandingMsisdn;


