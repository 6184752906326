import store from '../redux/store';
import appConfig from '../application/config';
import gaEvents from '../application/gaEvents';

import User from './user';
import Router from '../utils/router';

class Utils {

	static gaEvents() {
		return gaEvents;
	}

	static getEnv() {
		return process.env.REACT_APP_ENV;
	}

	static getStore() {
		const getStore = store.getState().storeState.config;
		return getStore;
	}

	static getApiUrl() {
		return appConfig.apiConnection.baseAPI[process.env.REACT_APP_ENV];
	}

	/**
	 * Move scroll to page top when the in pages redirected
	 * @param {*} path
	 * @param {*} cb
	 */
	static scrollGoToTop(path, cb) {
		if ((path.state && !path.state.doScrollTop) === undefined) {
			let x = (path.state && path.state.scrollX) ? path.state.scrollX : 0;
			let y = (path.state && path.state.scrollY) ? path.state.scrollY : 0;

			setTimeout(() => {
				window.scrollTo(x, y);
				if (cb) {
					return cb();
				}
			}, appConfig.application.secureTransitionPageTime);
		} else {
			if (cb) {
				return cb();
			}
		}
	}

	/**
	 * Sets a temporary class to an especific dom node by Id
	 * @param className
	 * @param time
	 * @param id
	 */
	static setTemporaryClass(className, time, id) {
		if (document.getElementById(id))
			document.getElementById(id).className += ' ' + className;
		setTimeout(() => {
			if (document.getElementById(id))
				document.getElementById(id).className = document.getElementById(id).className.replace(' ' + className, '');
		}, time);
	}

	/**
	 * Get a value from localStorage by the key
	 * @param {*} key
	 */
	static getLocalStorage(key) {
		return JSON.parse(localStorage.getItem(key));
	}

	/**
	 * Insert value into localStorage
	 * @param {*} key
	 * @param {*} value
	 */
	static setLocalStorage(key, value) {
		localStorage.setItem(key, JSON.stringify(value));
	}

	/**
	 * Clear all values from localStorage
	 */
	static clearAllLocalStorage() {
		localStorage.clear();
	}

	/**
	 * Clear a value from localStorage
	 * @param {*} key
	 */
	static clearLocalStorage(key) {
		localStorage.removeItem(key);
	}

	/**
	 * Sends pageview to Google Analytics
	 * @param path
	 */
	static gaPageview(path) {
		let ga = window.ga;
		if (ga) ga('send', 'pageview', path);
	}

	/**
	 * Send data to Google Analytics
	 * @param category
	 * @param action
	 * @param label
	 * @param value
	 */
	static gaSend(_category, action, _label, value) {
		let ga = window.ga;
		let category = (_category ? _category : (User.isLogged() ? gaEvents.Category.Logado : gaEvents.Category.NaoLogado));
		let label = _label !== undefined ? _label : '';

		if (ga) {
			console.log('category: ' + category + '\n' + 'action: ' + action + '\n' + 'label: ' + label)
			ga('send', 'event', category, action, label, value);
		}
	}

	/**
	 * Return true if ops is Android
	 */
	static isAndroid() {
		return !!navigator.userAgent.match(/Android/i);
	}

	static openContestoneMas(_label) {
		let isHe = User.isHe();
		let isLogged = User.isLogged();
		let isContestoneMas = User.isContestoneMas();

		if (isLogged || isHe) {
			if (isContestoneMas) {
				Router.goToModal({ pathname: appConfig.web.routes.googleplay, state: { modal: true, label: _label } });
			} else {
				Router.goToModal({ pathname: appConfig.web.routes.contestoneMas, state: { modal: true, subscribe: true } });
			}
		} else {
			Router.goToModal({ pathname: appConfig.web.routes.contestoneMas, state: { modal: true, subscribe: false } });
		}
	}
}

export default Utils;
