import React, { useState } from 'react'

import Modal from './modal'
import User from "../../utils/user";
import Utils from "../../utils/utils";
import Router from "../../utils/router";
import UserApi from "../../redux/api/user-api";
import appConfig from "../../application/config";

import ErrorMessage from '../shared/errorMessage'

const PerfilCancelMasOk = () => {
	const webRoutes = appConfig.web.routes;
	let [errorType, setErrorType] = useState(false)
	const gaEvents = Utils.gaEvents()

	const unsubscribeTones = () => {
		Utils.gaSend('', gaEvents.Action.CancelarAssinatura, gaEvents.Label.UsuarioMas+' - '+gaEvents.Label.Downgrade)
		User.subscribe(appConfig.planTypes.UNLIMITED).then(() =>{
			Router.goToModal({pathname: webRoutes.perfilDowngrade, state: {modal: true}})
		}).catch((error)=>{
			setErrorType(error)
		})
	}

	const unsubscribeTonesAndRBT = () => {
		Utils.gaSend('', gaEvents.Action.CancelarAssinatura, gaEvents.Label.UsuarioMas+' - '+gaEvents.Label.Cancelar)
		UserApi.unsubscribe().then(() =>{
			Router.goToModal({pathname: webRoutes.perfilCancelMas, state: {modal: true}})
		}).catch((error)=>{
			setErrorType(error)
		})
	}

	return (
		<Modal
			bg={''}
			type={'3'}
			AreaName={gaEvents.Action.CancelarAssinatura}
			Label={gaEvents.Label.UsuarioMas}
			content={
				<>
					<p className='font-weight-bold'>Al cancelar tu suscripción perderás los tonos que escucha quien te llama</p>
					<p>¿Que quieres cancelar?</p>
					<ErrorMessage type={errorType} resetErrorType={setErrorType} />
					<div className="buttons flex-column">
						<div className="content">
							<div className="button type-1" onClick={unsubscribeTones}>
								<span>tonos para tu celular</span>
							</div>
							<div className="button type-1" onClick={unsubscribeTonesAndRBT}>
								<span>tonos para tu celular y para quien te llama</span>
							</div>
						</div>
					</div>
				</>
			}
		/>
	)
}

export default PerfilCancelMasOk


