import store from '../redux/store';
import { REDIRECT_AUTH_CALLBACK } from '../redux/actions';
import UserApi from '../redux/api/user-api';

import User from './user';
import Router from './router';
import appConfig from '../application/config';

const webRoutes = appConfig.web.routes;

class Tone {

	static queueAdd() {
		this.queue = (!this.queue) ? 1 : this.queue += 1;
	}

	static queueSub() {
		this.queue = (this.queue < 1) ? 0 : this.queue -= 1;
	}

	/**
	 * Checks if tone is Freeemium
	 * @param id
	 * @returns {boolean}
	 */
	static isFreemium(id) {
		if (!id) return false;

		let catalog = store.getState().catalogState.catalog;

		let catalogFreemium = catalog.find(item => item.behavior === 'FREEMIUM');

		if (!catalogFreemium) return false;

		let highlights = catalogFreemium.highlights.find(item => item.id === id);

		let tones = catalogFreemium.tones.find(item => item.id === id);

		return !!highlights || !!tones;
	}

	/**
	 * Verify Tone has Active
	 * @param id
	 * @returns {boolean}
	 */
	static isActive(id) {
		try {
			let toneBoxes = store.getState().userState.info.toneBoxes;
			return !!toneBoxes.find(tb =>
				tb.toneBoxType === appConfig.toneBoxTypes.DEFAULT
				&& !!tb.tones.find(t => t.id === id)
			);
		} catch (e) {
			return false;
		}
	}

	/**
	 * Check conditions and active tone
	 * @param tone
	 * @returns {Promise}
	 */
	static active(tone) {
		let userLogged = User.isLogged();
		let isHe = User.isHe();

		if (isHe && !userLogged) {
			// HE sem assinatura deslogado
			if (Tone.isFreemium(tone.id)) {
				Router.goToModal({ pathname: webRoutes.welcomeFreemium, state: { modal: true } });
				store.dispatch({
					type: REDIRECT_AUTH_CALLBACK,
					authCallback: () => this.active(tone)
				});
				return Promise.reject();

			} else {
				Router.goToModal({ pathname: webRoutes.upsellRbt, state: { modal: true } });
				store.dispatch({
					type: REDIRECT_AUTH_CALLBACK,
					authCallback: () => this.active(tone)
				});
				return Promise.reject();
			}

		} else if (!userLogged) {
			// Não reconhecido
			Router.goToModal({ pathname: webRoutes.auth, state: { modal: true, isFreemium: Tone.isFreemium(tone.id) } });
			store.dispatch({
				type: REDIRECT_AUTH_CALLBACK,
				authCallback: () => this.active(tone)
			});
			return Promise.reject();
		}

		if (!Tone.isFreemium(tone.id)) {
			// Reconhecido e sem plano ou
			// Reconhecido e com plano Freemium tentando ativar tone não gratis
			if (User.isFreemium() || !User.isSubscribed()) {
				Router.goToModal({ pathname: webRoutes.upsellRbt, state: { modal: true } });
				store.dispatch({
					type: REDIRECT_AUTH_CALLBACK,
					authCallback: () => this.active(tone)
				});
				return Promise.reject();
			}
		} else {
			// Reconhecido e com plano Freemium tentando ativar o sexto tone
			if (User.isFreemium() && !User.isAbleToActivateTone(this.queue)) {
				Router.goToModal({ pathname: webRoutes.gratis5Tones, state: { modal: true } });
				store.dispatch({
					type: REDIRECT_AUTH_CALLBACK,
					authCallback: () => this.active(tone)
				});
				return Promise.reject();
			} else if (!User.isSubscribed()) {
				// Reconhecido e sem plano tentando ativar tone gratis
				Router.goToModal({ pathname: webRoutes.welcomeFreemium, state: { modal: true } });
				store.dispatch({
					type: REDIRECT_AUTH_CALLBACK,
					authCallback: () => this.active(tone)
				});
				return Promise.reject();
			}
		}

		this.queueAdd();
		return UserApi.userAddTone(tone).finally(() => {
			this.queueSub();
		});
	}

	/**
	 * Remove Tone
	 * @param tone
	 * @returns {Promise}
	 */
	static remove(tone) {
		let toneBoxes = store.getState().userState.info.toneBoxes;
		let toneBox = toneBoxes.find(tb => tb.toneBoxType === appConfig.toneBoxTypes.DEFAULT);
		return UserApi.userRemoveTone({ ...toneBox, tones: [tone] });
	}
}

export default Tone;
