// React
import React from 'react';
import {connect} from 'react-redux';

// Internationalization
import Messages from '../../application/stores/messages'
import {IntlProvider, addLocaleData} from 'react-intl';
import LocaleEN from 'react-intl/locale-data/en';
import LocalePT from 'react-intl/locale-data/pt';
import LocaleES from 'react-intl/locale-data/es';

addLocaleData([...LocaleEN, ...LocalePT, ...LocaleES]);

class IntlSwapProvider extends React.Component {
	render() {
		let lang, alias;
		try {
			alias = Messages[this.props.store.ct]
			lang = this.props.store.language
		} catch (e) {
			alias = Messages["mx"]
			lang = "mx"
		}
		return (
			<IntlProvider locale={lang} messages={alias}>
				{this.props.children}
			</IntlProvider>
		);
	}
}

const mapStateToProps = function (store) {
	return {
		store: store.storeState.config
	};
};

export default connect(mapStateToProps)(IntlSwapProvider);