import {TOOLTIP_SEARCH, TOOLTIP_TONE, TOOLTIP_TONE_RESET} from '../actions'

const initialState = {
	search: false,
	stripe: '',
	tone: {
    show: false,
		element: null,
		isActive: false,
		idTone: false,
		message: false,
		deactivate: () => {
		},
		lastElement: null
	}
}

export const tooltipReducer = (state = initialState, action) => {
	switch (action.type) {
    case TOOLTIP_TONE:
		  if (state.tone.lastElement !== action.state.element && !action.state.show)
		    return {...state}
		  else
        return {
          ...state,
          tone: {
            ...state.tone,
            ...action.state,
            lastElement: action.state.element
          }
        }

    case TOOLTIP_TONE_RESET:
			return {
				...state,
				tone: {
					...initialState.tone,
					lastElement: state.tone.lastElement
				}
			};

		case TOOLTIP_SEARCH:
			return {
				...state,
				search: action.state
			};

		default:
			return state;
	}
};