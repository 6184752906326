import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

import appConfig from '../../application/config';
import Router from "../../utils/router";
import Utils from "../../utils/utils";

import logotype from '../../assets/svg/logotype-contestone.svg';
import LandingApi from "../../redux/api/user-api";

const LandingRedirect = () => {
	const webRoutes = appConfig.web.routes;
	const {toneId, msisdn} = useSelector(state => state.landingState);

	useEffect(() => {
		if (!!toneId) {
			LandingApi.userAddTone(toneId, msisdn).finally(redirectAction);
		} else {
			redirectAction();
		}
	}, []);

	const redirectAction = () => {
		if (Utils.isAndroid()) {
			setTimeout(() => document.getElementById("LINK").click(), 2000);
		} else {
			Router.goTo({pathname: webRoutes.tones})
		}
	}

	return (
		<section className="redirect">
			<figure className="logotype">
				<img src={logotype} alt=""/>
			</figure>
			<h1>¡Bienvenido <br />a Contestone!</h1>
			<h2>Espera un rato encuanto<br />te redireccionamos</h2>
			<a id={"LINK"} style={{visibility: "hidden"}} href={"intent://"+appConfig.Stores.mexico.url+"#Intent;scheme=rbt;package=" + appConfig.Stores.mexico.appLink + ";end"}>Marcelo juninho</a>
		</section>
	)
}

export default LandingRedirect;


