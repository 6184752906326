module.exports = {
	apiConnection: {
		imagesAssetsAPI: {
			development: 'https://hmg.cms.imusica.com.br/cms/rbt/image/',
			homolog: 'https://hmg.cms.imusica.com.br/cms/rbt/image/',
			production: 'https://images.telcelcontestone.com/cms/rbt/image/'
		},
		baseAPI: {
			development: 'http://hmg-api-rbt.int.imusicacorp.com',
			homolog: 'http://hmg-api-rbt.int.imusicacorp.com',
			production: 'https://api.clarorbt.com/',
		},
		paths: {
			Category: 'rbtServices/web/tone/catalog/id',
			CategoryList: 'rbtServices/web/tone/catalog/list',
			Catalog: 'rbtServices/web/tone/catalog/editorial',
			Tone: {
				Add: 'rbtServices/web/tone/add',
				Delete: 'rbtServices/web/tone/del',
				Search: 'rbtServices/web/tone/search',
				Singer: 'rbtServices/web/tone/search/singer',
				Single: 'rbtServices/web/tone/search/single',
			},
			User: {
				Edit: 'rbtServices/web/user/edit',
				Info: 'rbtServices/web/user/info',
				Login: 'rbtServices/web/user/login',
				Msisdn: 'rbtServices/web/user/msisdn',
				Upgrade: 'rbtServices/web/user/upgrade',
				Validate: 'rbtServices/web/user/validate',
				PlanList: 'rbtServices/web/user/plan/list',
				Subscribe: 'rbtServices/web/user/subscribe',
				Unsubscribe: 'rbtServices/web/user/unsubscribe',
				SubscriptionInfo: 'rbtServices/web/user/subscription/info',
				DeletePersonalData: 'rbtServices/web/user',
			},
			Editorial: {
				Help: 'rbtServices/web/user/faq',
				Terms: 'rbtServices/web/user/privacy',
				TermsFreemium: 'rbtServices/web/user/terms_freemium',
				// Privacy: 'rbtServices/web/user/privacy',
			},
		},
		timeout: 120000,
		dbModelDebug: {
			request: true,
			response: true,
		},
	},
	application: {
		secureTransitionPageTime: 100,
	},
	web: {
		routes: {
			home: '/',
			landing: {
				main: '/landing',
				he: '/landing/he',
				error: '/landing/error',
				msisdn: '/landing/msisdn',
				pincode: '/landing/pincode',
				redirect: '/landing/redirect',
			},
			contact: '/contact',
			contestoneMas: '/contestone-mas',
			contestoneMasOk: '/contestone-mas-ok',
			welcomeFreemium: '/welcome-freemium',
			welcomeAgain: '/welcome-again',
			upsellRbt: '/upsell-rbt',
			upsellRbtOk: '/upsell-rbt-ok',
			gratis5Tones: '/gratis-5tones',
			auth: '/auth',
			react: '/react',
			music: '/music',
			tones: '/tones',
			perfil: '/perfil',
			categories: '/categories',
			category: '/category',
			googleplay: '/googleplay',
			perfilCancelUnlimited: '/perfil-cancel-unlimited',
			perfilCancelUnlimitedOk: '/perfil-cancel-unlimited-ok',
			perfilDowngrade: '/perfil-downgrade',
			perfilCancelMas: '/perfil-cancel-mas',
			perfilCancelMasOk: '/perfil-cancel-mas-ok',
			perfilExit: '/perfil-exit',
			terms: '/terms',
			deleteData: '/delete-data',
			deleteDataConfirmation: '/delete-data-confirmation',
			deleteDataConfirmationSuccess: '/delete-data-sucess',
			deleteDataConfirmationError: '/delete-data-error',
			help: '/help',
			rules: '/rules',
			errorTone: '/error-tone',
			errorModal: '/error-modal',
		},
		modalsRouter: [
			'contestone-mas',
			'contestone-mas-ok',
			'welcome-again',
			'welcome-freemium',
			'categories',
			'auth',
			'upsell-rbt',
			'upsell-rbt-ok',
			'gratis-5tones',
			'googleplay',
			'perfil-cancel-unlimited',
			'perfil-cancel-unlimited-ok',
			'perfil-downgrade',
			'perfil-cancel-mas',
			'perfil-cancel-mas-ok',
			'perfil-exit',
			'terms',
			'help',
			'rules',
			'error-tone',
			'error-modal',
			'delete-data-confirmation',
			'delete-data-sucess',
			'delete-data-error',
		],
	},
	landing: {
		partnerIds: {
			ClaroIdeas: 1,
			IVR: 2,
			Contenedor: 3,
			MITelcel: 4,
		},
		texts: {
			freemium: {
				h1: '¡Bienvenido a<br className="line-break" />Contestone Gratis!',
				button: 'confirmar',
				description: 'Haciendo click, aceptas los <a href="#">Términos y Condiciones</a>',
			},
			unlimited: {
				h1: '¡Activa tonos para<br className="line-break" />quien te llama',
				h2: 'Solo $4,99 semanal<br />IVA incluido',
				button: 'subscribir',
				description: 'Haciendo click, aceptas los <a href="javascript:;">Términos y Condiciones</a>',
			},
			mas: {
				h1: '¡Activa tonos para tu celular<br />y para quien te llama',
				h2: 'Solo $9,99 sem anal<br />IVA incluido',
				button: 'subscribir',
				description: 'Haciendo click, aceptas los <a href="javascript:;">Términos y Condiciones</a>',
			},
		},
	},
	maxTonesFree: 5,
	toneBoxTypes: {
		DEFAULT: 'DEFAULT',
	},
	statusTypes: {
		DELETED: 'DELETED',
	},
	planCodes: {
		BRAND_PLAN_FREEMIUM: 30,
		BRAND_PLAN_CONTESTONE_7DIAS: 55,
		BRAND_PLAN_CONTESTONE: 56,
		BRAND_PLAN_CONTESTONE_MAS_7DIAS: 59,
		BRAND_PLAN_CONTESTONE_MAS: 60,
	},
	planTypes: {
		FREEMIUM: 'plan_freemium',
		UNLIMITED: 'Contestone',
		UNLIMITED7DAYS: 'Contestone 7 Dias Gratis',
		CONTESTONE_MAS: 'Contestone Más',
		CONTESTONE_MAS7DAYS: 'Contestone Más 7 Dias Gratis',
	},
	contenedor: {
		partner: [{
			id: 1,
			name: 'Portal Sim',
		},
		{
			id: 2,
			name: 'Claro Ideas',
		},
		{
			id: 3,
			name: 'Contenedor Telcel',
		},
		{
			id: 4,
			name: 'IVR *111 Gráfico',
		}],
	},
	Stores: {
		mexico: {
			url: 'telcelcontestone.com/landing',
			appLink: 'com.rbt.android',
			host: {
				development: /localhost:3000|192\.168\.100\.147:3000/,
				homolog: /^(http|https):\/\/((www|wap|statics|m|cms)\.)?hmg\.telcelcontestone\.com|(http|https):\/\/127\.0\.0\.1:8080/,
				production: /^(http|https):\/\/((www|wap|statics|m|cms)\.)?(telcelcontestone|clarorbt)\.com|(http|https):\/\/127\.0\.0\.1:8080/,
			},
		},
		brasil: {
			host: {
				development: /localhost:3001/,
				homolog: /^(http|https)\/\/((www|wap|statics|m|cms)\.)?(br\.clarorbt\.com|clarosomdechamada\.com\.br)|localhost:3000/,
				production: /^(http|https)\/\/((www|wap|statics|m|cms)\.)?(br\.clarorbt\.com|clarosomdechamada\.com\.br)|localhost:3000/,
			},
		},
		peru: {
			host: {
				development: /localhost:3002/,
				homolog: /^(http|https)\/\/((www|wap|statics|m|cms)\.)?(br\.clarorbt\.com|clarosomdechamada\.com\.br)|localhost:3000/,
				production: /^(http|https)\/\/((www|wap|statics|m|cms)\.)?(br\.clarorbt\.com|clarosomdechamada\.com\.br)|localhost:3000/,
			},
		},
		colombia: {
			host: {
				development: /localhost:3003/,
				homolog: /^(http|https)\/\/((www|wap|statics|m|cms)\.)?(br\.clarorbt\.com|clarosomdechamada\.com\.br)|localhost:3000/,
				production: /^(http|https)\/\/((www|wap|statics|m|cms)\.)?(br\.clarorbt\.com|clarosomdechamada\.com\.br)|localhost:3000/,
			},
		},
		argentina: {
			host: {
				development: /localhost:3004/,
				homolog: /^(http|https)\/\/((www|wap|statics|m|cms)\.)?(br\.clarorbt\.com|clarosomdechamada\.com\.br)|localhost:3000/,
				production: /^(http|https)\/\/((www|wap|statics|m|cms)\.)?(br\.clarorbt\.com|clarosomdechamada\.com\.br)|localhost:3000/,
			},
		},
		costarica: {
			host: {
				development: /localhost:3005/,
				homolog: /^(http|https)\/\/((www|wap|statics|m|cms)\.)?(br\.clarorbt\.com|clarosomdechamada\.com\.br)|localhost:3000/,
				production: /^(http|https)\/\/((www|wap|statics|m|cms)\.)?(br\.clarorbt\.com|clarosomdechamada\.com\.br)|localhost:3000/,
			},
		},
		elsalvador: {
			host: {
				development: /localhost:3006/,
				homolog: /^(http|https)\/\/((www|wap|statics|m|cms)\.)?(br\.clarorbt\.com|clarosomdechamada\.com\.br)|localhost:3000/,
				production: /^(http|https)\/\/((www|wap|statics|m|cms)\.)?(br\.clarorbt\.com|clarosomdechamada\.com\.br)|localhost:3000/,
			},
		},
		guatemala: {
			host: {
				development: /localhost:3007/,
				homolog: /^(http|https)\/\/((www|wap|statics|m|cms)\.)?(br\.clarorbt\.com|clarosomdechamada\.com\.br)|localhost:3000/,
				production: /^(http|https)\/\/((www|wap|statics|m|cms)\.)?(br\.clarorbt\.com|clarosomdechamada\.com\.br)|localhost:3000/,
			},
		},
		honduras: {
			host: {
				development: /localhost:3008/,
				homolog: /^(http|https)\/\/((www|wap|statics|m|cms)\.)?(br\.clarorbt\.com|clarosomdechamada\.com\.br)|localhost:3000/,
				production: /^(http|https)\/\/((www|wap|statics|m|cms)\.)?(br\.clarorbt\.com|clarosomdechamada\.com\.br)|localhost:3000/,
			},
		},
		nicaragua: {
			host: {
				development: /localhost:3009/,
				homolog: /^(http|https)\/\/((www|wap|statics|m|cms)\.)?(br\.clarorbt\.com|clarosomdechamada\.com\.br)|localhost:3000/,
				production: /^(http|https)\/\/((www|wap|statics|m|cms)\.)?(br\.clarorbt\.com|clarosomdechamada\.com\.br)|localhost:3000/,
			},
		},
		ecuador: {
			host: {
				development: /localhost:3010/,
				homolog: /^(http|https)\/\/((www|wap|statics|m|cms)\.)?(br\.clarorbt\.com|clarosomdechamada\.com\.br)|localhost:3000/,
				production: /^(http|https)\/\/((www|wap|statics|m|cms)\.)?(br\.clarorbt\.com|clarosomdechamada\.com\.br)|localhost:3000/,
			},
		},
	},
};
