import React from 'react';
import Play from '../buttons/play'
import FigureTone from '../shared/figure-tone'

import Ringtone from '../buttons/ringtone'
import Contestone from '../buttons/contestone'

const CardTone = (props) => {
	return (
		<div className="card-tono">
			<div className="container">
				<div className="content">
					<Play Tone={props.Tone} Label={props.Label} />
					<div className="details">
						<div className="title">{props.Tone.name}</div>
						<div className="artist">{props.Tone.singer.name}</div>
					</div>
					<div className="actions">
						<Ringtone Tone={props.Tone} Label={props.Label} />
						<Contestone Tone={props.Tone} Label={props.Label} />
					</div>
				</div>
				<FigureTone Tone={props.Tone} />
			</div>
		</div>
	);
}

export default CardTone
