import React from 'react'
import {useSelector} from 'react-redux'

function Loading() {
	const Loading = useSelector(state => state.loadingState)
	return (
		<div className={"loading-component" + (Loading.isVisible ? ' fadeIn' : ' fadeOut')}>
			<div className="loading-container">
				<div className="icon-loading-white"/>
			</div>
		</div>
	)
}

export default Loading
