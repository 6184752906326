import {REDIRECT_RESET, REDIRECT_AUTH_CALLBACK} from '../actions'

const initialState = {
	authCallback: null
}

export const redirectReducer = (state = initialState, action) => {
	switch (action.type) {
		case REDIRECT_RESET:
			return {...state};

		case REDIRECT_AUTH_CALLBACK:
			return {
				...state,
				authCallback: action.authCallback
			};

		default:
			return state;
	}
};